import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import axios from "axios";
import { Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

//add dataTable ---- Bootstrap and jQuery libraries
//import 'bootstrap/dist/css/bootstrap.min.css';
//---import 'jquery/dist/jquery.min.js';

//import "datatables.net-dt/js/dataTables.dataTables";
//import "datatables.net-dt/css/jquery.dataTables.min.css";
//import $ from "jquery";

import imgSubCategoria from "./../../../assets/images/imgSubcategoria.png";

import MensajeCargando from "./../../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../../hooks/mensajeArrayVacio";
import FErrorMessage from "./../../../hooks/FErrorMessage";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const RegSubcategoria = () => {
  const [modalInsertar, setModalInsertar] = useState(false);

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const [categoria, setCategoria] = useState([]);
  const [data, setData] = useState([]); //data =subCategoria
  const [valor, setValor] = useState(0);

  //Categoria
  const peticionCategGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}categoria/gestion`) //lista departamentos
      .then((response) => {
        setCategoria(response.data); //inicializo y lo guardo en setdepartamento
      });
  };

  const peticionsubcategGet = async (opcion) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}subcategoria/gestion&categoria_id=${opcion}`
      )
      .then((response) => {
        //setSubCategoria(response.data);
        setData(response.data);
      });
  };

  const handlerCargarSubcateg = function (e) {
    const opcion = e.target.value;
    setValor(opcion);
    //console.log(opcion);
    peticionsubcategGet(opcion);
    //---peticionsubcategGet(valor); para los demas
  };

  //-----------------------------------------------------
  //con FORMIK
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  const initialValues = {
    //categoria_id: "",
    categoria_id: valor,
    nombre: "",
    visualizacion: "",
    imagen: "",
  };

  const validationSchema = Yup.object({
    //busqExistencia: Yup.string().required("Por favor seleccione uno"),
    //departamento_id:'0',
    nombre: Yup.string().required("Por favor agregue un Nombre"),
    visualizacion: Yup.string().required("Seleccione una opcion"),
  });

  //-----------------------------------------------------
  const peticionPost = async (valores) => {
    //console.log("Antes: " + valores);
    var f = new FormData();
    f.append("categoria_id", valores.categoria_id);
    f.append("nombre", valores.nombre);
    f.append("visualizacion", valores.visualizacion);
    f.append("imagen", valores.imagen);
    //console.log(valores);

    await axios
      .post(`${process.env.REACT_APP_URL_API}subcategoria/save`, f)
      .then((response) => {
        peticionsubcategGet(valor); //actualizar la tabla de la subCateg(valor)
        abrirCerrarModalInsertar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  //editar
  const [modalEditar, setModalEditar] = useState(false);
  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const [valorSeleccionado, setValorSeleccionado] = useState({});

  //una funcion que permita asignar al estado cual es la fila que le selecciono, tambien se va reutilizar para eliminar => tenemos un segundo parametro
  const seleccionar = (valor, caso) => {
    setValorSeleccionado(valor);
    //console.log(valorSeleccionado);
    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };

  const initialValuesEdit = {
    categoria_id: valorSeleccionado.categoria_id,
    nombre: valorSeleccionado.nombre,
    visualizacion: valorSeleccionado.visualizacion,
    imagen: "",
    imagenAnterior: valorSeleccionado.imagen,
  };

  const peticionPut = async (valores) => {
    console.log("antesPut", valores);
    var f = new FormData();
    f.append("categoria_id", valores.categoria_id);
    f.append("nombre", valores.nombre);
    f.append("visualizacion", valores.visualizacion);
    f.append("imagenAnterior", valores.imagenAnterior);
    f.append("imagen", valores.imagen);

    await axios
      .post(
        `${process.env.REACT_APP_URL_API}subcategoria/actualizar&id=${valorSeleccionado.id}`,
        f
      ) 
      .then((response) => {
        //setData(dataNueva);
        peticionsubcategGet(valor); //actualizar la tabla de la subCateg(valor)
        abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  //delete
  const [modalEliminar, setModalEliminar] = useState(false);
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionDelete = async () => {
    var f = new FormData();
    // f.append("nombre", valorSeleccionado.nombre);
    //f.append("visualizacion", valorSeleccionado.visualizacion);
    f.append("imagen", valorSeleccionado.imagen);
    console.log(valorSeleccionado);

    await axios
      .post(
        `${process.env.REACT_APP_URL_API}subcategoria/eliminar&id=${valorSeleccionado.id}`,
        f
      )
      .then((response) => {
        //antes  setData(data.filter(valor => valor.id !== valorSeleccionado.id)); //filtroData, se quedan todos los elementos que sean diferentes al id seleccionado .. luego del filtrado se agrega al estado y luego cerrar el modal ... Tb se debe de add el manejo de errores
        peticionsubcategGet(valor); //actualizar la tabla de la subCateg(valor)
        abrirCerrarModalEliminar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  useEffect(() => {
    peticionCategGet();
  }, []);

  //-----------------------------------------------------
  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        <Row>
          <Col xs={12} sm={6} md={6}>
            <h2 className="formulario__titulo"> Reg. SubCategoria </h2>
            <br />

            <label htmlFor="categoria_id"> <b>Categoria </b> </label>
            <select
              name="categoria_id"
              as="select"
              onClick={handlerCargarSubcateg}
              className={"form-control"}
            >
              <option value="0"> --- select categoria --- </option>
              {categoria.map(({ id, nombre }) => (
                <option key={id} value={id}>
                  {nombre}
                </option>
              ))}
            </select>
          </Col>

          <Col xs={12} sm={6} md={6}>
            <br />
            <br />
            <br />
            <br />
            <button className="btn btn-success" onClick={() => abrirCerrarModalInsertar()}> Insertar SubCategoria </button>
          </Col>
        </Row>
        <br />

        <Row>
          <h2> Tabla de SubCategoria  </h2>
          <div className="tab-scroll-vertical">
            {data ? (
              data.length === 0 ? (
                <MensajeArrayVacio mensaje=" No se encontraron SubCategorias  " />
              ) : (
                <table id="regCategoria2" className="table table-striped">
                  <thead>
                    <tr>
                      <th> ID </th>
                      <th> Imagen </th>
                      <th> Nombre </th>
                      <th> Acciones </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((valor) => (
                      <tr key={valor.id}>
                        <td>{valor.id}</td>                        
                        <td>
                          <img
                            src={
                              valor.imagen
                                ? valor.imagen !== ""
                                  ? `${process.env.REACT_APP_URL_API}uploads/${valor.imagen}`
                                  : `${imgSubCategoria}`
                                : `${imgSubCategoria}`
                            }
                            alt={valor.nombre}
                            title={valor.nombre}
                            width="75px"
                          />
                        </td>
                        <td>
                          {valor.visualizacion === "SI" ? (
                            <span className="babel label label-successN"> {valor.visualizacion} </span>
                          ) : (
                            <span className="babel label label-dangerN"> {valor.visualizacion} </span>
                          )}
                          &nbsp; &nbsp; {valor.nombre}
                        </td>
                        <td>
                          <button className="btn btn-simple btn-warningNs" onClick={() => seleccionar(valor, "Editar")}> <FontAwesomeIcon icon={faEdit} /> </button>
                          &nbsp;
                          <button className="btn btn-simple btn-dangerNs" onClick={() => seleccionar(valor, "Eliminar")}> <FontAwesomeIcon icon={faTrash} /> </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            ) : (
              <MensajeCargando mensaje="Cargando Lista de SubCategorias" />
            )}
          </div>
        </Row>

        {/* <h2> mostrarModals </h2> */}

        {/* MODAL */}
        <Modal isOpen={modalInsertar}>
          <ModalHeader>
            <h2>Ingresar SubCategoria </h2>
          </ModalHeader>
          <ModalBody>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(valores, { resetForm }) => {
                  peticionPost(valores);
                  // console.log(valores);

                  resetForm(); //limpia el formulario, pero te pone como al inicio
                  //console.log("Formulario enviado");
                  cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                  setTimeout(() => cambiarFormularioEnviado(false), 2000);
                }}
              >
                {(formik) => (
                  <Form>
                    <Row>
                      <Col className="form-group" xs={12} sm={12} md={12}>
                        <label htmlFor="categoria_id">Categoria </label>
                        <Field
                          name="categoria_id"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> --- select categoria --- </option>
                          {categoria.map(({ id, nombre }) => (
                            <option key={id} value={id}>
                              {nombre}
                            </option>
                          ))}
                        </Field>
                        <FErrorMessage name="categoria_id" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="nombre">Nombre</label>
                        <Field
                          type="text"
                          name="nombre"
                          placeholder="agregar nombre"
                          className={"form-control"}
                        />
                        <FErrorMessage name="nombre" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="visualizacion">Visualizacion</label>
                        <Field
                          name="visualizacion"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> - Seleccionar - </option>
                          <option value="SI"> SI </option>
                          <option value="NO"> NO </option>
                        </Field>
                        <FErrorMessage name="visualizacion" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="imagen">Imagen</label>
                        <Input
                          type="file"
                          name="imagen"
                          className={"form-control"}
                          onChange={(event) =>
                            formik.setFieldValue(
                              "imagen",
                              event.target.files[0]
                            )
                          }
                        />
                        <FErrorMessage name="imagen" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="password"> </label> <br />
                        <button type="submit" className="btn btn-primary">
                          Registrar
                        </button>
                        {formularioEnviado && (
                          <p className="formulario__msj-enviado"> Enviado subcateg!!! </p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => abrirCerrarModalInsertar()}> Cancelar </button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={modalEditar}>
          <ModalHeader>
            <h2> Actualizar SubCategoria </h2>
          </ModalHeader>
          <ModalBody>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValuesEdit}
                validationSchema={validationSchema}
                onSubmit={(valores, { resetForm }) => {
                  peticionPut(valores);

                  //console.log(valores);

                  resetForm(); //limpia el formulario, pero te pone como al inicio
                  //console.log("Formulario enviado");
                  cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                  setTimeout(() => cambiarFormularioEnviado(false), 2000);
                }}
              >
                {(formik) => (
                  <Form>
                    <Row>
                      <Col className="form-group" xs={12} sm={12} md={12}>
                        <label htmlFor="categoria_id">Categoria </label>
                        <Field
                          name="categoria_id"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> --- select categoria --- </option>
                          {categoria.map(({ id, nombre }) => (
                            <option key={id} value={id}>
                              {nombre}
                            </option>
                          ))}
                        </Field>
                        <FErrorMessage name="categoria_id" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="nombre">Nombre</label>
                        <Field
                          type="text"
                          name="nombre"
                          placeholder="agregar nombre"
                          className={"form-control"}
                        />
                        <FErrorMessage name="nombre" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="visualizacion">Visualizacion</label>
                        <Field
                          name="visualizacion"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> - Seleccionar - </option>
                          <option value="SI"> SI </option>
                          <option value="NO"> NO </option>
                        </Field>
                        <FErrorMessage name="visualizacion" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <Input type="hidden" name="imagenAnterior" />

                        <label htmlFor="imagen">Imagen</label>
                        <Input
                          type="file"
                          name="imagen"
                          className={"form-control"}
                          onChange={(event) =>
                            formik.setFieldValue(
                              "imagen",
                              event.target.files[0]
                            )
                          }
                        />
                        <FErrorMessage name="imagen" />

                        <img
                          src={`${process.env.REACT_APP_URL_API}uploads/${valorSeleccionado.imagen}`}
                          alt={valorSeleccionado.nombre}
                          title={valorSeleccionado.nombre}
                          width="75px"
                        />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="password"> </label> <br />
                        <button type="submit" className="btn btn-primary">  Actualizar </button>
                        &nbsp;
                        {formularioEnviado && (
                          <p className="formulario__msj-enviado"> Actualizar subcateg!!! </p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => abrirCerrarModalEditar()}> Cancelar </button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={modalEliminar}>
          <ModalBody>
            ¿Estás seguro que deseas eliminar la SubCategoria -{" "}
            {valorSeleccionado && valorSeleccionado.nombre}?
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => peticionDelete()}> Sí </button>
            <button className="btn btn-secondary" onClick={() => abrirCerrarModalEliminar()}> No </button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default RegSubcategoria;
