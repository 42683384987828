import React from "react";
import { Row } from "react-bootstrap";

import avatar from "./../../assets/images/user.png";
import useAuth from "../../hooks/useAuth";

import Config from "../user/Config";

const Perfil = () => {
  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        <Row>
          <h1> Perfil </h1>
          <p> add: Actualizar perfil, cambiar contraseñas, otras cosas </p>
          <Config />
        </Row>
      </div>
    </>
  );
};
export default Perfil;
