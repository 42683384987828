import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faTag,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

//add dataTable ---- Bootstrap and jQuery libraries
//import 'bootstrap/dist/css/bootstrap.min.css';
//---import 'jquery/dist/jquery.min.js';

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import MensajeCargando from "./../../../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../../../hooks/mensajeArrayVacio";

import imgEmpresa from "./../../../../assets/images/imgEmpresa.png";

import ModalInfoDatos from "./../modal/ModalInfoDatos";
import TabEstadisticas from "./../modal/tabEstadisticas";

const ListarBusqueda = ({ peticion }) => {
  const [data, setData] = useState();
  //const [data, setData] = useState(); antes

  const [modalDatos, setModalDatos] = useState(false);
  const [modalEstadistica, setModalEstadistica] = useState(false);

  const abrirCerrarModalDatos = () => {
    setModalDatos(!modalDatos);
  };

  const abrirCerrarModalEstadistica = () => {
    setModalEstadistica(!modalEstadistica);
  };

  const [valorSeleccionado, setValorSeleccionado] = useState({});

  const seleccionarEmpresa = (valor, caso) => {
    setValorSeleccionado(valor);
    if (caso === "datos") {
      abrirCerrarModalDatos();
    } else if (caso === "estadistica") {
      abrirCerrarModalEstadistica();
    } else {
      console.log("no entro a ninguna");
    }
  };

  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  const peticionGet = async () => {
    //console.log(`ES::::${process.env.REACT_APP_URL_API}${peticion}`);
    await axios
      .get(`${process.env.REACT_APP_URL_API}${peticion}`)

      .then((response) => {
        console.log(response.data); //muestra en console F12
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //-----------------------------------------------------
  useEffect(() => {
    //setData(); //add setiembre
    peticionGet();
  }, [peticion]);

  //-----------------------------------------------------

  return (
    <>     

        <p>Mostrar peticion (para comprobar): {peticion} </p>

        {data ? (
          data.length === 0 ? (
            <MensajeArrayVacio mensaje=" No se encontraron Empresas " />
          ) : (
            <table id="tabListarBusqueda" className="table table-striped">
              <thead>
                <tr>
                  <th> ID </th>
                  <th> Logo </th>
                  <th> Nombre Emp </th>
                  <th> Reg. Tributario </th>
                  <th> Categoría </th>
                  <th> Subcategoría </th>
                  <th> Pago </th>
                  <th> Acciones </th>
                </tr>
              </thead>
              <tbody>
                {data.map((valor) => (
                  <tr key={valor.id}>
                    <td>{valor.id}</td>
                    <td>
                      <img
                        src={
                          valor.imagen
                            ? valor.imagen !== ""
                              ? `${process.env.REACT_APP_URL_API}uploads/${valor.imagen}`
                              : `${imgEmpresa}`
                            : `${imgEmpresa}`
                        }
                        alt={valor.nombre}
                        title={valor.nombre}
                        width="75px"
                      />
                    </td>
                    <td> {valor.nombre}</td>
                    <td>
                      <span className="label label-infoN"> {valor.reg_abreviatura} </span> 
                      &nbsp; &nbsp; {valor.num_reg_trib}
                    </td>
                    <td>{valor.cat_nombre}</td>
                    <td>{valor.subcateg_nombre}</td>
                    <td>Free xxx </td>
                    <td>
                      <button className="btn btn-simple btn-warningNs" onClick={() => seleccionarEmpresa(valor, "datos")}> <FontAwesomeIcon icon={faMapMarkerAlt} /> DatosGenerales /PagWeb /Tiendas </button>
                      &nbsp; &nbsp; 
                      <button className="btn btn-simple btn-infoNs" onClick={() => seleccionarEmpresa(valor, "estadistica")}> <FontAwesomeIcon icon={faTag} />  Estadisticas </button>
                      {/*
              <button className="btn btn-simple btn-dangerNs"><FontAwesomeIcon icon={faTrash} />Pagos</button>
              <button className="btn btn-simple btn-dangerNs"><FontAwesomeIcon icon={faEdit} /> PDF </button>
              */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        ) : (
          <MensajeCargando mensaje="Cargando Lista de Categorias" />
        )}

        <Modal isOpen={modalDatos} size="lg">
          <ModalHeader> Empresa </ModalHeader>
          <ModalBody>
            <div>
              <ModalInfoDatos
                id={valorSeleccionado.id}
                reg_abreviatura={valorSeleccionado.reg_abreviatura}
                num_reg_trib={valorSeleccionado.num_reg_trib}
                cat_nombre={valorSeleccionado.cat_nombre}
                subcateg_nombre={valorSeleccionado.subcateg_nombre}
                nombre={valorSeleccionado.nombre}
                email={valorSeleccionado.email}
                descripcion={valorSeleccionado.descripcion}
                productos={valorSeleccionado.productos}
                imagen={
                  valorSeleccionado.imagen
                    ? valorSeleccionado.imagen !== ""
                      ? `${process.env.REACT_APP_URL_API}uploads/${valorSeleccionado.imagen}`
                      : `${imgEmpresa}`
                    : `${imgEmpresa}`
                }
                fecha_registro={valorSeleccionado.fecha_registro}
                celular={valorSeleccionado.celular}
                estado_delete={valorSeleccionado.estado_delete}
                fecha_inicio={valorSeleccionado.fecha_inicio}
                fecha_final={valorSeleccionado.fecha_final}
                rol={valorSeleccionado.rol}
                perNombre={valorSeleccionado.perNombre}
                perApellido={valorSeleccionado.perApellido}
                perEmail={valorSeleccionado.perEmail}
                perCelular={valorSeleccionado.perCelular}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary"  onClick={() => abrirCerrarModalDatos()}> Cerrar </button>
          </ModalFooter>
        </Modal>
        

        <Modal isOpen={modalEstadistica} size="lg">
          <ModalHeader> Empresa - Estadistica </ModalHeader>
          <ModalBody>
            <div className="paddingTab">
              <TabEstadisticas id={valorSeleccionado.id} />
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={() => abrirCerrarModalEstadistica()}> Cancelar </button>
          </ModalFooter>
        </Modal>
   
    </>
  );
};

export default ListarBusqueda;
