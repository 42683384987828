export const SerializeForm = (form) =>{
	
	const formData = new FormData(form); //acceder a los datos dentro del formulario

	const  completeObj = {}; //vacia y saque clave, valor

	//console.log(formData);

	//let data = let [name, value] ya desestructurado es mejor
	for(let [name, value] of formData){
		//console.log(name, value);
		completeObj[name] = value;
	}

	return completeObj;

}