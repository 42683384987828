import React from "react";

import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

import { Container, Nav, Navbar } from "react-bootstrap";

//import imgCervantes3 from "./../../assets/imagen/claudia-cervantes-jilaja-2.jpg";

import avatar from "../../../assets/images/user.png";

const Menu = () => {
  const [scrolled, setScrolled] = useState(false);

  //mostrar el scroll cuando la medida del desplace sea mayor a 50
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <Navbar className={scrolled ? "scrolled" : ""} expand="lg" variant="light" >
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="logo">
          Proy <img alt="House" src={avatar} width="30" height="30" />{" "}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto"> 
              <Nav.Link as={NavLink} to="/"> Inicio </Nav.Link>
              <Nav.Link as={NavLink} to="/categorias"> Categorias </Nav.Link>
              <Nav.Link as={NavLink} to="/busqueda">Busqueda</Nav.Link>
              <Nav.Link as={NavLink} to="/nosotros">Nosotros</Nav.Link>
              <Nav.Link as={NavLink} to="/servicios">Servicios</Nav.Link>
              <Nav.Link as={NavLink} to="/contacto">Contacto</Nav.Link>

            </Nav>

            <Nav>
              <Nav.Link as={NavLink} to="/login"> Inicia Sesión </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Menu;
