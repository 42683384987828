import {useState, useEffect} from 'react';
import axios from 'axios';


//cosntruir un hooks, es llevar la logica que te de la gana a una funcion y la llamas con use
const useAxiosGet = (endpoint) => {

	const [data, setData] = useState();

	const peticionGet=async()=>{
		await axios.get(`${process.env.REACT_APP_URL_API}${endpoint}`) 
		.then(response=>{
			//console.log(response.data); //muestra en console F12
			setData(response.data);
		}).catch((err)=>{
			console.log(err);
		})
	}	

	useEffect(()=>{
		peticionGet();
	},[endpoint])
	

	return data

}
export default useAxiosGet


