import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import axios from "axios";
import { Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

//add dataTable ---- Bootstrap and jQuery libraries
//import 'bootstrap/dist/css/bootstrap.min.css';
//---import 'jquery/dist/jquery.min.js';

//import "datatables.net-dt/js/dataTables.dataTables";
//import "datatables.net-dt/css/jquery.dataTables.min.css";
//import $ from "jquery";

import imgCategoria from "./../../../assets/images/imgCategoria.png";

import MensajeCargando from "./../../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../../hooks/mensajeArrayVacio";
import FErrorMessage from "./../../../hooks/FErrorMessage";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const RegCategoria = () => {
  const [data, setData] = useState([]);

  const [modalInsertar, setModalInsertar] = useState(false);

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  //mostrar lista de todas las categorias
  const peticionGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}categoria/gestion`)
      .then((response) => {
        //console.log(response.data); //muestra en console F12
        setData(response.data);
        //---setData([]);
      });
  };

  //con FORMIK
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  const initialValues = {
    nombre: "",
    visualizacion: "",
    imagen: "",
  };

  const validationSchema = Yup.object({
    //busqExistencia: Yup.string().required("Por favor seleccione uno"),
    //departamento_id:'0',
    nombre: Yup.string().required("Por favor agregue un Nombre"),
    visualizacion: Yup.string().required("Seleccione una opcion"),
  });

  //-----------------------------------------------------
  //revisar con imagen : https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik

  const peticionPost = async (valores) => {
    var f = new FormData();
    f.append("nombre", valores.nombre);
    f.append("visualizacion", valores.visualizacion);
    f.append("imagen", valores.imagen);
    //console.log(valores);
   
    await axios
      .post(`${process.env.REACT_APP_URL_API}categoria/save`, f)
      .then((response) => {
        //antes  setData(data.concat(response.data)); //si es correcta que se concatene al estado data. Antes: setData(response.data) AHORA:
        peticionGet();
        abrirCerrarModalInsertar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  //editar
  const [modalEditar, setModalEditar] = useState(false);
  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const [valorSeleccionado, setValorSeleccionado] = useState({});

  //una funcion que permita asignar al estado cual es la fila que le selecciono, tambien se va reutilizar para eliminar => tenemos un segundo parametro
  const seleccionar = (valor, caso) => {
    setValorSeleccionado(valor);
    //console.log(valorSeleccionado);
    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };

  const initialValuesEdit = {
    nombre: valorSeleccionado.nombre,
    visualizacion: valorSeleccionado.visualizacion,
    imagen: "",
    imagenAnterior: valorSeleccionado.imagen,
  };

  const peticionPut = async (valores) => {
    console.log("antesPut", valores);
    var f = new FormData();
    f.append("nombre", valores.nombre);
    f.append("visualizacion", valores.visualizacion);
    f.append("imagenAnterior", valores.imagenAnterior);
    f.append("imagen", valores.imagen);

    await axios
      .post(
        `${process.env.REACT_APP_URL_API}categoria/actualizar&id=${valorSeleccionado.id}`,
        f
      ) 
      .then((response) => {
        //var dataNueva = data;
        /*dataNueva.map( (valor) => {
				if(valor.id === valorSeleccionado.id){
					valor.nombre = valores.nombre;
          valor.visualizacion = valores.visualizacion;
          valor.imagen = valores.imagen;
				}
			});		
      */
        //setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  //delete
  const [modalEliminar, setModalEliminar] = useState(false);
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionDelete = async () => {
    var f = new FormData();
    // f.append("nombre", valorSeleccionado.nombre);
    //f.append("visualizacion", valorSeleccionado.visualizacion);
    f.append("imagen", valorSeleccionado.imagen);
    console.log(valorSeleccionado);

    await axios
      .post(
        `${process.env.REACT_APP_URL_API}categoria/eliminar&id=${valorSeleccionado.id}`,
        f
      )
      .then((response) => {
        //antes  setData(data.filter(valor => valor.id !== valorSeleccionado.id)); //filtroData, se quedan todos los elementos que sean diferentes al id seleccionado .. luego del filtrado se agrega al estado y luego cerrar el modal ... Tb se debe de add el manejo de errores
        peticionGet();
        abrirCerrarModalEliminar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  useEffect(() => {
    peticionGet();
  }, []);

  //-----------------------------------------------------

  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        <Row>
          <Col xs={12} sm={6} md={6}>
            <h2 className="formulario__titulo"> Reg. Categoria </h2>
          </Col>

          <Col xs={12} sm={6} md={6}>
            <button className="btn btn-success" onClick={() => abrirCerrarModalInsertar()} >
               Insertar Categoria
            </button>
          </Col>
        </Row>
        <br />

        <Row>
          <h2> Tabla de Categorias - con Imagen </h2>
          <div className="tab-scroll-vertical">
            {data ? (
              data.length === 0 ? (
                <MensajeArrayVacio mensaje=" No se encontraron Categorias " />
              ) : (
                <table id="regCategoria2" className="table table-striped">
                  <thead>
                    <tr>
                      <th> ID </th>
                      <th> Imagen </th>
                      <th> Nombre </th>
                      <th> Acciones </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((valor) => (
                      <tr key={valor.id}>
                        <td>{valor.id}</td>
                        <td>
                          <img
                            src={
                              valor.imagen
                                ? valor.imagen !== ""
                                  ? `${process.env.REACT_APP_URL_API}uploads/${valor.imagen}`
                                  : `${imgCategoria}`
                                : `${imgCategoria}`
                            }
                            alt={valor.nombre}
                            title={valor.nombre}
                            width="75px"
                          />
                        </td>
                        <td>
                          {valor.visualizacion === "SI" ? (
                            <span className="babel label label-successN">{valor.visualizacion}</span>
                          ) : (
                            <span className="babel label label-dangerN">{valor.visualizacion} </span>
                          )}
                          &nbsp; &nbsp; {valor.nombre}
                        </td>
                        <td>
                          <button className="btn btn-simple btn-warningNs" onClick={() => seleccionar(valor, "Editar")} > <FontAwesomeIcon icon={faEdit} /> </button> 
                          &nbsp;
                          <button className="btn btn-simple btn-dangerNs" onClick={() => seleccionar(valor, "Eliminar")}> <FontAwesomeIcon icon={faTrash} /> </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            ) : (
              <MensajeCargando mensaje="Cargando Lista de Categorias" />
            )}
          </div>
        </Row>



        {/* MODAL */}
        <Modal isOpen={modalInsertar}>
          <ModalHeader>
            <h2>Ingresar Categoria </h2>
          </ModalHeader>
          <ModalBody>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(valores, { resetForm }) => {                 
                 
                  peticionPost(valores);
                  // console.log(valores);

                  resetForm(); //limpia el formulario, pero te pone como al inicio
                  console.log("Formulario enviado");
                  cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                  setTimeout(() => cambiarFormularioEnviado(false), 2000);
                
                }}
              >
                {(formik) => (
                  <Form>
                    {/*
                  <Row>
                    <Col>
                      <h2>Registro de Categorías - sinImagen</h2>
                      <hr />
                    </Col>
                  </Row>
                  */}

                    <Row>
                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="nombre">Nombre</label>
                        <Field
                          type="text"
                          name="nombre"
                          placeholder="agregar nombre"
                          className={"form-control"}
                        />
                        <FErrorMessage name="nombre" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="visualizacion">Visualizacion</label>
                        <Field
                          name="visualizacion"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> - Seleccionar - </option>
                          <option value="SI"> SI </option>
                          <option value="NO"> NO </option>
                        </Field>
                        <FErrorMessage name="visualizacion" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="imagen">Imagen</label>
                        <Input
                          type="file"
                          name="imagen"
                          className={"form-control"}
                          onChange={(event) =>
                            formik.setFieldValue(
                              "imagen",
                              event.target.files[0]
                            )
                          }
                        />
                        <FErrorMessage name="imagen" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="password"> </label> <br />
                        <button type="submit" className="btn btn-primary"> Registrar </button>
                        {formularioEnviado && (
                          <p className="formulario__msj-enviado"> Enviado categ!!! </p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => abrirCerrarModalInsertar()} > Cancelar </button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={modalEditar}>
          <ModalHeader>
            <h2> Actualizar Categoria  </h2>
          </ModalHeader>
          <ModalBody>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValuesEdit}
                validationSchema={validationSchema}
                onSubmit={(valores, { resetForm }) => {
                  peticionPut(valores);
                
                  //console.log(valores);

                  resetForm(); //limpia el formulario, pero te pone como al inicio
                  console.log("Formulario enviado");
                  cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                  setTimeout(() => cambiarFormularioEnviado(false), 2000);
                }}
              >
                {(formik) => (
                  <Form>
                    {/*
                  <Row>
                    <Col>
                      <h2>Registro de Categorías - sinImagen</h2>
                      <hr />
                    </Col>
                  </Row>
                  */}

                    <Row>
                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="nombre">Nombre</label>
                        <Field
                          type="text"
                          name="nombre"
                          placeholder="agregar nombre"
                          className={"form-control"}
                        />
                        <FErrorMessage name="nombre" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="visualizacion">Visualizacion</label>
                        <Field
                          name="visualizacion"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> - Seleccionar - </option>
                          <option value="SI"> SI </option>
                          <option value="NO"> NO </option>
                        </Field>
                        <FErrorMessage name="visualizacion" />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <Input type="hidden" name="imagenAnterior" />

                        <label htmlFor="imagen">Imagen</label>
                        <Input
                          type="file"
                          name="imagen"
                          className={"form-control"}
                          onChange={(event) =>
                            formik.setFieldValue(
                              "imagen",
                              event.target.files[0]
                            )
                          }
                        />
                        <FErrorMessage name="imagen" />

                        <img
                          src={`${process.env.REACT_APP_URL_API}uploads/${valorSeleccionado.imagen}`}
                          alt={valorSeleccionado.nombre}
                          title={valorSeleccionado.nombre}
                          width="75px"
                        />
                      </Col>

                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor="password"> </label> <br />
                        <button type="submit" className="btn btn-primary"> Actualizar </button>
                        &nbsp;
                        {formularioEnviado && (
                          <p className="formulario__msj-enviado"> Actualizar categ!!! </p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => abrirCerrarModalEditar()}> Cancelar </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalEliminar}>
          <ModalBody>
            ¿Estás seguro que deseas eliminar la Categoria -{" "}
            {valorSeleccionado && valorSeleccionado.nombre}?
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => peticionDelete()}> Sí </button>
            <button className="btn btn-secondary" onClick={() => abrirCerrarModalEliminar()}> No </button>
          </ModalFooter>
        </Modal>

      </div>
    </>
  );
};

export default RegCategoria;
