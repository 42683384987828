import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGlobe, faTags} from '@fortawesome/free-solid-svg-icons'
import {faFacebookF, faYoutube, faInstagram} from '@fortawesome/free-brands-svg-icons'



//import {useState} from 'react';
import axios from 'axios';


import useAxiosGet from '../../hooks/useAxiosGet.js'

//faChrome  faChrome
//target="_blank" id="emp.getIdEmpresa()" class="btn_puntPagWeb btn-fill btn-successN" style="font-size: 20px;" title="web"

//<a href="getPagWeb()" target="_blank" id="emp.getIdEmpresa()" class="btn_puntPagWeb btn-fill btn-successN" style="font-size: 20px;" title="web">  <FontAwesomeIcon icon={faArrowRight} /> </a>


//http://localhost:3000/empresas/Salon%20de%20Belleza/https://www.youtube.com/


const PtPagweb= ({
	id
}) =>{




	//const paginas = useAxiosGet(`categoria/obtenerCat&nombre=${param.categNombre}`) 
	const paginas = useAxiosGet(`empresa/paginas&empresa_id=${id}`);
	const puntMensual = useAxiosGet(`empresa/puntMensual&empresa_id=${id}`);



	//const [punWeb, setPunWeb] = useState();
	const peticionPuntPagWeb=async()=>{
		await axios.get(`${process.env.REACT_APP_URL_API}puntajeMensual/puntPagWeb&empresa_id=${id}`)  
		.then(response=>{
			//setPunWeb(response.data);
		}).catch(error =>{
			console.log(error);
			})
	}

	

	//const [punFacebook, setPunFacebook] = useState();
	const peticionPuntPagFacebook=async()=>{
		await axios.get(`${process.env.REACT_APP_URL_API}puntajeMensual/puntPagFacebook&empresa_id=${id}`)  
		.then(response=>{
			//setPunFacebook(response.data);
		}).catch(error =>{
			console.log(error);
			})
	}

	//const [punYoutube, setPunYoutube] = useState();
	const peticionPuntPagYoutube=async()=>{
		await axios.get(`${process.env.REACT_APP_URL_API}puntajeMensual/puntPagYoutube&empresa_id=${id}`)  
		.then(response=>{
			//setPunYoutube(response.data);
		}).catch(error =>{
			console.log(error);
			})
	}

	//const [punInstagram, setPunInstagram] = useState();
	const peticionPuntPagInstagram=async()=>{
		await axios.get(`${process.env.REACT_APP_URL_API}puntajeMensual/puntPagInstagram&empresa_id=${id}`)  
		.then(response=>{
			//setPunInstagram(response.data);
		}).catch(error =>{
			console.log(error);
			})
	}


	//const [punOtros, setPunOtros] = useState();
	const peticionPuntPagOtros=async()=>{
		await axios.get(`${process.env.REACT_APP_URL_API}puntajeMensual/puntPagOtros&empresa_id=${id}`)  
		.then(response=>{
			//setPunOtros(response.data);
		}).catch(error =>{
			console.log(error);
			})
	}



//expected !== and instead saw !=

	return(
		<>

		<b> Redes: </b> <br/>
		
		{
			paginas && puntMensual &&  (
				<>
				pun_pag_web:	{puntMensual.pun_pag_web}   <br/>
				pun_pag_facebook:  {puntMensual.pun_pag_facebook}  <br/>
				pun_pag_youtube: {puntMensual.pun_pag_youtube} <br/>
				pun_pag_instagram:  {puntMensual.pun_pag_instagram} <br/>
				pun_pag_otros: {puntMensual.pun_pag_otros} <br/>
				<br/>
	 			</>
            )
        }



		{
			paginas && puntMensual &&  (
				( (paginas.pag_web !== "") && (puntMensual.pun_pag_web !== 0)) ? (            	
	            	<a href={paginas.pag_web} target="_blank" title="web"   >
	            		<button className="redes-btn-fill redes-btn-successN"  onClick={()=>peticionPuntPagWeb()} > <FontAwesomeIcon icon={faGlobe} />    </button> 
	            	</a>
	            ) : (<span> </span>)
            )
        }


		{
			paginas && puntMensual &&  (
				( (paginas.pag_facebook !== "") && (puntMensual.pun_pag_facebook !== 0)) ? (            	
	            	<a href={paginas.pag_facebook} target="_blank" title="facebook"    >
	            		<button className="redes-btn-fill redes-btn-infoN"  onClick={()=>peticionPuntPagFacebook()} > <FontAwesomeIcon icon={faFacebookF}   />  </button> 
	            	</a>
	            ) : (<span> </span>)
            )
        }


		{
			paginas && puntMensual &&  (
				( (paginas.pag_youtube !== "") && (puntMensual.pun_pag_youtube !== 0)) ? (            	
	            	<a href={paginas.pag_youtube} target="_blank" title="youtube"   >
	            		<button className="redes-btn-fill redes-btn-dangerN"  onClick={()=>peticionPuntPagYoutube()} > <FontAwesomeIcon icon={faYoutube} />  </button>
	            	</a>
	            ) : (<span> </span>)
            )
        }


 		{
			paginas && puntMensual &&  (
				( (paginas.pag_instagram !== "") && (puntMensual.pun_pag_instagram !== 0)) ? (            	
	            	<a href={paginas.pag_instagram} target="_blank" title="instagram"   >
	            		<button className="redes-btn-fill redes-btn-warningN "  onClick={()=>peticionPuntPagInstagram()} >   <FontAwesomeIcon icon={faInstagram} />    </button>
	            	</a>
	            ) : (<span> </span>)
            )
        }         


		{
			paginas && puntMensual &&  (
				( (paginas.pag_otros !== "") && (puntMensual.pun_pag_otros !== 0)) ? (            	
	            	<a href={paginas.pag_otros} target="_blank" title="otros"   >
	            		<button className="redes-btn-fill redes-btn-petroleoN"  onClick={()=>peticionPuntPagOtros()} > <FontAwesomeIcon icon={faTags} />   </button>
	            	</a>
	            ) : (<span> </span>)
            )
        }


<br/><br/>
									
		</>
	)
}
export default PtPagweb
