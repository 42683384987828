import React from "react";

import { Outlet, Navigate } from "react-router-dom";

import Header from "./Header";
import Footer from "./../Footer";

import useAuth from "./../../../hooks/useAuth";

const PublicLayout = () => {
  //Restringir acceso a la parte publica -> si estoy identificado
  //ANTES <Outlet />
  const { auth } = useAuth();

  return (
    <>
      {/* LAYOUT*/}
      <Header />

      {/* Contenido principal */}
      <section>
        {!auth.id ? <Outlet /> : <Navigate to="/social" />}
        {/* Outlet, todos los componentes que cargue cada ruta */}
      </section>

      {/*footer*/}
      <Footer />
    </>
  );
};
export default PublicLayout;
