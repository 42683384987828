import React from "react";
import { Row } from "react-bootstrap";

import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll, faTag, faTags } from "@fortawesome/free-solid-svg-icons";

import useAxiosGet from "./../../../hooks/useAxiosGet";
import ListaEmpresas from "./../../lista/listaEmpresas";

import imgSubcategoria from "./../../../assets/images/imgSubcategoria.png";

const Empresas = () => {
  const param = useParams();
  //console.log("param-subcategNombre");
  //console.log(param.subcategNombre);

  const data = useAxiosGet(
    `subcategoria/obtenerSubcat&nombre=${param.subcategNombre}`
  );

  return (
    <>
      <div className="cabecera-menu"> </div>
      {data && (
        <div className="hheader hheader-empresas">
          <div className="hheader__thumbnail">
            {data.imagen !== "" ? (
              <img
                src={`${process.env.REACT_APP_URL_API}uploads/${data.imagen}`}
                alt={data.nombre}
                className="hheader__image"
              />
            ) : (
              <img
                src={imgSubcategoria}
                alt="subcategoria"
                className="hheader__image"
              />
            )}
          </div>
          <div className="hheader__texts">
            <p className="hheader__title">
              <FontAwesomeIcon icon={faTag} size="lg" /> {data.cat_nombre}
            </p>

            <h1 className="hheader__subtitle">
              <FontAwesomeIcon icon={faTags} size="lg" /> {data.nombre}
            </h1>

            <Link
              to={`/subcategorias/${data.cat_nombre}`}
              className="hheader__btn btn btn-simple btn-categNs"
            >
              <FontAwesomeIcon icon={faReplyAll} size="lg" /> SubCategoria
            </Link>
          </div>
        </div>
      )}

      <div className="item-section">
        <Row>
          {data && (
            <ListaEmpresas
              peticion={`empresa/gestion_subcateg&subcategoria_id=${data.id}`}
            />
          )}
        </Row>
      </div>
    </>
  );
};
export default Empresas;
