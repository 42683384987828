const PtProductos = ({ productos }) => {
  /*
//representar una cadena HTML al usar React, puede ser con: DangerlySetInnerHTML (similar al innerHTML) o sino 'react-html-parser' pero solo en la version 16
//  <div dangerouslySetInnerHTML={{__html: saludo}} />
//https://es.reactjs.org/docs/dom-elements.html
//https://remarkablemark.medium.com/an-alternative-to-dangerously-set-innerhtml-64a12a7c1f96
*/

  let productos_lista = "";

  if (productos === null || productos === "") {
    //no se encontraron productos
    productos_lista =
      '<span class="label label-dangerN"> No se encontraron Productos </span>';
  } else {
    //hay productos
    const label_productosAll = productos.replaceAll(
      ",",
      '</span>  <span class="label label-producto"> '
    );
    productos_lista = ` <span class="label label-producto">  ${label_productosAll}  </span>`;
    //rpta - cadena: <div dangerouslySetInnerHTML={{__html: label3}} />
  }

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: productos_lista }} />
    </>
  );
};
export default PtProductos;
