import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';
import useAuth from './../../hooks/useAuth';


const Logout = () =>{

	 const {setAuth} = useAuth();

	const navigate = useNavigate();

	useEffect( () =>{
		//vaciar el localstorage
		localStorage.clear(); //vacia todo el localstorage

		//setear estados globales a vacio
		setAuth({});
		//setCounters({});


		//navigate (redireccion al login)
		navigate("/login");


	})

	return(
		<div>  
			<h1> Cerrando Sesion ...  </h1>
		</div>
		)
}
export default Logout;