import React from "react";
import { Row } from "react-bootstrap";

import { Link, useParams } from "react-router-dom";

import useAxiosGet from "./../../../hooks/useAxiosGet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";

import imgCategoria from "./../../../assets/images/imgCategoria.png";
import ListaSubcategorias from "./../../lista/listaSubcategorias";

const Subcategorias = () => {
  const param = useParams();
  //console.log(param.categNombre);

  const data = useAxiosGet(`categoria/obtenerCat&nombre=${param.categNombre}`);

  return (
    <>
      <div className="cabecera-menu"> </div>
      {data && (
        <div className="hheader hheader-subcateg">
          <div className="hheader__thumbnail">
            {data.imagen !== "" ? (
              <img
                src={`${process.env.REACT_APP_URL_API}uploads/${data.imagen}`}
                alt={data.nombre}
                className="hheader__image"
              />
            ) : (
              <img
                src={imgCategoria}
                alt="categoria"
                className="hheader__image"
              />
            )}
          </div>
          <div className="hheader__texts">
            <p className="hheader__title">{data.nombre} </p>

            <p className="hheader__paragraph">
              A floral, solar and voluptuous interpretation composed by Olivier
              Polge, Perfumer-Creator for the House of CHANEL.
            </p>

            <Link
              to={`/categorias`}
              className="hheader__btn btn btn-simple btn-categNs"
            >
              <FontAwesomeIcon icon={faReplyAll} size="lg" /> Categorias
            </Link>
          </div>
        </div>
      )}
  
      <div className="item-section">
        <Row>
          {data && (
            <>
              <ListaSubcategorias
                peticion={`subcategoria/gestionEmpresas&categoria_id=${data.id}`}
              />
            </>
          )}
        </Row>
      </div>
    </>
  );
};
export default Subcategorias;
