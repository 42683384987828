import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStore} from '@fortawesome/free-solid-svg-icons'

const Tienda = ({
	id, 
	empresa_id, 
	distrito_id, 
	direccion, 
	telefono, 
	celular, 
	horario, 
	visualizacion, 
	dep_nombre, 
	prov_nombre, 
	dist_nombre
	}) =>{


	return(
		<>
			<Accordion.Item eventKey={id}>
			    <Accordion.Header> <FontAwesomeIcon icon={faStore} />  
					&nbsp; &nbsp;  Tienda  &nbsp; 
					{ visualizacion ==="NO" && (  <span className='label label-dangerN'>  NO mostrada </span>)   }
				</Accordion.Header>
			    <Accordion.Body>
				    <div className="card-tiendas">
						<b> Dirección: </b> {direccion}. {dist_nombre} - {prov_nombre}, {dep_nombre}, Perú. <br/>
						<b> Teléfono: </b> {telefono} <br/>
						<b> Celular: </b> {celular} <br/>
						<b> Horario: </b> {horario} <br/>
					</div>
			    </Accordion.Body>
		  	</Accordion.Item>
		</>
	)
}
export default Tienda

