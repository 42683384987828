import React, { useState } from "react";

import useForm from "./../../hooks/useForm";
import { Global } from "./../../helpers/Global";

import useAuth from "./../../hooks/useAuth";
import avatar from "./../../assets/images/user.png";

import { SerializeForm } from "./../../helpers/SerializeForm";

import axios from "axios";

/*
formulario actualizar
- 1. add el form mas imagen (campos del formulario)
- 2. rellenar el formulario con informacion real opcion 2 (auth) -- (1-sacar info con el localstorage, 2-utlizar nuestro estado global (auth) e imprimir dentro la informacion, 3-id y hacer una peticion al backend  )
- 3. recoger la informacion del formulario (serializar e.target de cada campo) y hacer la peticion de actualizar () 




*/

const Config = () => {
  const { auth, setAuth } = useAuth(); //tengo los datos del usuario
  const [saved, setSaved] = useState("not_sendedNoEnviado");

  const updateUser = async (e) => {
    e.preventDefault();

    //token de autenticacion
    const token = localStorage.getItem("token");

    //Llenar el formulario con auth
    console.log("auth");
    console.log(auth);

    //Recoger datos del formulario
    //SerializeForm(e.target); //para los datos del formulario
    console.log("serializable");
    console.log(SerializeForm(e.target)); //los datos del formulario clave:valor de todos
    let newDataUser = SerializeForm(e.target);

    //Borrar propiedad innecesaria
    //-----
    delete newDataUser.imagen;
    console.log("newDataUser");
    console.log(newDataUser);

    //Actualizar usuario en la base de datos

    var f = new FormData();
    f.append("nombre", newDataUser.nombre);
    f.append("apellido", newDataUser.apellido);
    f.append("email", newDataUser.email);
    f.append("password", newDataUser.password);
    f.append("rol", newDataUser.rol);

    await axios
      .post(Global.url + "usuario/update&id=" + auth.id, f) //Request URL: http://localhost/react_api_proy01/proyecto-php-poo-mvc-sql-apicategoria/categorias/update&id=69
      .then((response) => {
        //var dataNueva = data;

        //setData(dataNueva);

        console.log("se update");
        /*const dataN= response.data;
		      console.log("xxxx");
		      console.log(dataN);  // dataN = newUser */

        //delete.data.user.password;
        //setAuth(data.user);
        //setAuth();
        //------------------------------------------
        console.log("new auth");
        axios
          .get(Global.url + "usuario/editar&id=" + auth.id)
          .then((response) => {
            //console.log(response.data); //muestra en console F12
            setAuth(response.data); //todo el objeto menos la contraseña
            //---setData([]);
          });
        console.log(auth);
        console.log("finalllll");
        //------------------------------------------
        setSaved("saved");
      })
      .catch((error) => {
        console.log(error);
        setSaved("error");
      });

   
    
  };

  return (
    <>
      <header className="content__header content__header--public">
        <h1 className="content__title">Configuración / Ajustes </h1>
      </header>

      <div className="content__posts">
        <p>ES: {saved} es todo</p>

        {saved === "saved" ? (
          <strong className="alert alert-success">
            {" "}
            Usuario actualizado correctamente !!{" "}
          </strong>
        ) : (
          ""
        )}

        {saved === "error" ? (
          <strong className="alert alert-danger">
            {" "}
            Usuario no se ha actualizado !!!{" "}
          </strong>
        ) : (
          ""
        )}

        <form className="config-form" onSubmit={updateUser}>
          <div className="form-group">
            <label htmlFor="nombre"> Nombre </label>
            <input type="text" name="nombre" defaultValue={auth.nombre} />
          </div>

          <div className="form-group">
            <label htmlFor="apellido"> Apellidos </label>
            <input type="text" name="apellido" defaultValue={auth.apelllido} />
          </div>

          <div className="form-group">
            <label htmlFor="rol"> Nick </label>
            <input type="text" name="rol" defaultValue={auth.rol} />
          </div>

          <div className="form-group">
            <label htmlFor="bio"> Bio </label>
            <textarea name="bio" defaultValue={auth.rol} />
          </div>

          <div className="form-group">
            <label htmlFor="email"> Correo Electronico </label>
            <input type="email" name="email" defaultValue={auth.email} />
          </div>

          <div className="form-group">
            <label htmlFor="password"> Constraseña </label>
            <input
              type="password"
              name="password"
              defaultValue={auth.password}
            />
          </div>

          <div className="form-group">
            <label htmlFor="imagen"> Avatar </label>
            <div className="avatar">
              {/* mostrar imagen*/}
              <div className="general-info__container-avatar">
                {auth.imagen !== "default.png" && (
                  <img
                    src={Global.url + "uploads/" + auth.imagen}
                    className="container-avatar__img"
                    alt="Foto de perfil"
                  />
                )}
                {auth.imagen === "default.png" && (
                  <img
                    src={avatar}
                    className="container-avatar__img"
                    alt="Foto de perfil"
                  />
                )}
              </div>
            </div>
            <br />
            <input type="file" name="imagen" id="file" />
            <br /> <br />
          </div>

          <input type="submit" value="Actualizar" className="btn btn-success" />
        </form>
      </div>
    </>
  );
};
export default Config;
