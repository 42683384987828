import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import { Modal, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import MensajeCargando from "./../../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../../hooks/mensajeArrayVacio";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import FErrorMessage from "./../../../hooks/FErrorMessage";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const RegDepartamento = () => {
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  const [data, setData] = useState([]);

  const validationSchema = Yup.object({
    nombre: Yup.string().required("Por favor ingrese un valor"),
  });

  //-----------------------------------------------------
  const peticionGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}lugar/listaDepTienda`)
      .then((response) => {
        //console.log(response.data); //muestra en console F12
        setData(response.data);
      });
  };

  //-----------------------------------------------------
  const peticionPost = async (valores) => {
    var f = new FormData();
    f.append("nombre", valores.nombre);

    await axios
      .post(`${process.env.REACT_APP_URL_API}lugar/saveDep`, f)
      .then((response) => {
        setData(data.concat(response.data)); //si es correcta que se concatene al estado data. Antes: setData(response.data) AHORA:
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  const [valorSeleccionado, setValorSeleccionado] = useState({});

  //una funcion que permita asignar al estado cual es la fila que le selecciono, tambien se va reutilizar para eliminar => tenemos un segundo parametro
  const seleccionar = (valor, caso) => {
    setValorSeleccionado(valor);
    //console.log(valorSeleccionado);
    //(caso ==="Editar")?	abrirCerrarModalEditar(): abrirCerrarModalEliminar()
    if (caso === "Eliminar") {
      abrirCerrarModalEliminar();
    }
  };

  const initialValues = {
    nombre: "",
  };

  const initialValuesEdit = {
    id: valorSeleccionado.id,
    nombre: valorSeleccionado.nombre,
  };

  //-----------------------------------------------------
  //update
  const peticionPut = async (valores) => {
    var f = new FormData();
    f.append("nombre", valores.nombre);

    //await axios.post(baseUrl+'categorias/save', f, {params: {id: categoriaSeleccionado.id}} ) //add otro parametro 'id'  //http://localhost/react_api_proy01/proyecto-php-poo-mvc-sql-apicategoria/NuevoCategoriasController.php?id=69
    await axios
      .post(
        `${process.env.REACT_APP_URL_API}lugar/updateDep&id=${valorSeleccionado.id}`,
        f
      ) //Request URL: http://localhost/react_api_proy01/proyecto-php-poo-mvc-sql-apicategoria/categorias/update&id=69
      .then((response) => {
        var dataNueva = data;
        dataNueva.map((valor) => {          
          if (valor.id === valorSeleccionado.id) {
            valor.nombre = valores.nombre;
          }        
        });
        setData(dataNueva);
        //abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  //delete
  const [modalEliminar, setModalEliminar] = useState(false);
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionDelete = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_URL_API}lugar/eliminarDep&id=${valorSeleccionado.id}`
      )
      .then((response) => {
        setData(data.filter((valor) => valor.id !== valorSeleccionado.id)); //filtroData, se quedan todos los elementos que sean diferentes al id seleccionado .. luego del filtrado se agrega al estado y luego cerrar el modal ... Tb se debe de add el manejo de errores

        setValorSeleccionado({});
        abrirCerrarModalEliminar();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    peticionGet();

    setTimeout(() => {
      $("#tabRegDepartamento").DataTable({
        destroy: true,
        dom: "rBftlip",
        buttons: [{}],
        lengthMenu: [
          [10, 20, 50, 100, -1],
          [10, 20, 50, 100, "Todo"],
        ],
        language: {
          lengthMenu: "Mostrar _MENU_ registros",
          zeroRecords: "No se encontraron resultados",
          info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          infoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          infoFiltered: "(filtrado de un total de _MAX_ registros)",
          sSearch: "Buscar:",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          sProcessing: "Procesando..." /*,*/,
        },
        pageLength: 10,
      });
    }, 1000);
  }, []);
  
  
  return (
    <>    
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        <Row>
          <Col xs={12} sm={6} md={4}>
           
            <Card className="formulario">
              <Card.Header className="formulario__titulo">Departamento</Card.Header>
              <Card.Body className="formulario__body">
                <Formik
                  enableReinitialize
                  //initialValues={initialValues}
                  initialValues={valorSeleccionado.id >= 1 ? initialValuesEdit : initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(valores, { resetForm }) => {
                    console.log("peticionPost");

                    //----------------------------
                    //peticionPost(valores);
                    if (valorSeleccionado.id >= 1) {
                      peticionPut(valores);
                      setValorSeleccionado({});
                      //setValorSeleccionado(null);
                    } else {
                      peticionPost(valores);
                    }

                    resetForm(); //limpia el formulario, pero te pone como al inicio
                    console.log("Formulario enviado");
                    cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                    setTimeout(() => cambiarFormularioEnviado(false), 2000);
                  }}
                >
                  {({ errors, handleChange }) => (
                    <Form>
                      <Row>
                        <Col className="form-group" xs={12} sm={12} md={12}>
                          <label htmlFor="nombre">Departamento</label>
                          <Field
                            type="text"
                            name="nombre"
                            placeholder="Agregar Descripción"
                            className="form-control"
                          />
                          <FErrorMessage name="nombre" />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="form-group" xs={12}>
                          <label htmlFor=""> </label> <br />
                          {valorSeleccionado.id >= 1 ? (
                            <button type="submit" className="btn btn-primary">UpdateDep</button>
                          ) : (
                            <button type="submit" className="btn btn-primary">GuardaDep</button>
                          )}
                          {formularioEnviado && (
                            <p className="formulario__msj-enviado"> Enviado Dep!!! </p>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={6} md={8} height="350">
            <h2> Tabla de Departamentos </h2>
            <div className="tab-scroll-vertical">
              {data ? (
                data.length === 0 ? (
                  <MensajeArrayVacio mensaje=" No se encontraron los Departamentos " />
                ) : (
                  <table id="tabRegDepartamento3" className="table table-striped">
                    <thead>
                      <tr>
                        <th> ID </th>
                        <th> Nombre </th>
                        <th> Acciones </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((valor) => (
                        <tr key={valor.id}>
                          <td>{valor.id}</td>
                          <td>{valor.nombre}</td>
                          <td>
                            <button
                              className="btn btn-simple btn-warningNs"
                              onClick={() => seleccionar(valor, "Editar")}
                            > <FontAwesomeIcon icon={faEdit} /> </button> &nbsp;
                            <button
                              className="btn btn-simple btn-dangerNs"
                              onClick={() => seleccionar(valor, "Eliminar")}
                            > <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              ) : (
                <MensajeCargando mensaje="Cargando Lista de Departamentos" />
              )}
            </div>
          </Col>
        </Row>

        <Modal isOpen={modalEliminar}>
          <ModalBody>
            ¿Estás seguro que deseas eliminar el Departamento - {valorSeleccionado && valorSeleccionado.nombre}?
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => peticionDelete()}>Sí</button>
            <button className="btn btn-secondary" onClick={() => abrirCerrarModalEliminar()}>No</button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default RegDepartamento;
