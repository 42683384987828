import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import useAuth from "./../../hooks/useAuth";

import FErrorMessage from "./../../hooks/FErrorMessage";

import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const Login = () => {

  const navigate = useNavigate();

  const [saved, setSaved] = useState("not_sendedNoEnviado");
  const { setAuth } = useAuth();


  const initialValues = {
    ruc: "",
    password: "",
  };

  const validationSchema = Yup.object({
    //ruc: Yup.getIn().required("Agregue su RUC"), //add ruc add numeros  y que sean 8 y no letras
    password: Yup.string().required("Agregue su Password"),
  });

  
  const peticionPost = async (valores) => {
 
    var f = new FormData(); 
    f.append("ruc", valores.ruc);
    f.append("pwd", valores.password);

    await axios
      .post(`${process.env.REACT_APP_URL_API}empresa/login`, f)
      .then((response) => {
        //---setData(data.concat(response.data)); //se puede concatenar, pero no se muestra los nombres de categ y subcategoria
        const data = response.data;

        //Persistir los datos en el navegador (localstorage)  -> si son correctos //
        localStorage.setItem("token", "token12345");
        localStorage.setItem("user", JSON.stringify(data));

        //context -> crea un estado global con los datos del usuarioAutenticado
        //comprobar con el backend el 'token' y validarla
        setSaved("login");

        //setear los datos en el auth -- actualizar los datos del auth
        setAuth(data);

        //add redireccion a la pag principalPrivada
        setTimeout(() => {
          //window.location.reload();
          navigate("/social");
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setSaved("error");
      });
  };

  return (
    <>
      <div className="cabecera-menu"> </div>

      <div className="item-section">

        <Row className="aligh-items-center">
          <header className="content__header content__header--public">
            <h1 className="formulario__titulo2">Login </h1>
          </header>

          <div className="content__posts">
            {saved === "login" ? (
              <strong className="alert alert-success">Usuario identificado correctamente !!</strong>
            ) : ("")
            }

            {saved === "error" ? (
              <strong className="alert alert-danger">Usuario no se ha identificado !!!</strong>
            ) : ("")
            }
          </div>

          <div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(valores, { resetForm }) => {
                peticionPost(valores);

                resetForm(); 
                console.log("Formulario enviado");
                            
              }}
            >
              {({ errors }) => (
                <Form >
                  <Row>
                    <Col className="form-group" xs={12} sm={6} md={6}>
                      <label htmlFor="ruc">Num. RUC</label>
                      <Field
                        type="text"
                        name="ruc"
                        placeholder="Agregar RUC"
                        className={"form-control"}
                      />
                      <FErrorMessage name="ruc" />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group" xs={6} sm={6} md={6}>
                      <label htmlFor="password">Password</label>
                      <Field
                        type="text"
                        name="password"
                        placeholder="Agregar password"
                        className={"form-control"}
                      />
                      <FErrorMessage name="password" />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group" xs={12} sm={4} md={2}>
                      <label htmlFor=""> </label> <br />
                      <button type="submit" className="btn btn-primary">
                        Iniciar Sesión
                      </button>                      
                    </Col>
                  </Row>
                  
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </div>
    </>
  );
};
export default Login;
