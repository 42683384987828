import React from "react";
import { Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faTags,
  faArrowRight,
  faEnvelope,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import PtProductos from "./../../../unidad/ptProductos"; //parte-publica

const TabDatos = ({
  id,
  reg_abreviatura,
  num_reg_trib,
  cat_nombre,
  subcateg_nombre,
  nombre,
  email,
  descripcion,
  productos,
  imagen,
  fecha_registro,
  celular,
  estado_delete,
  rol,
  perNombre,
  perApellido,
  perEmail,
  perCelular,
}) => {
  return (
    <>
      <br />

      <Row>
        <Col xs={12} sm={8}>
          <h2> {nombre} </h2>
          <span class="label label-categsubcateg">
            <FontAwesomeIcon icon={faTag} /> {cat_nombre} &nbsp;
            <FontAwesomeIcon icon={faArrowRight} /> &nbsp;
            <FontAwesomeIcon icon={faTags} /> {subcateg_nombre}
          </span>
          <br />
          <br />
          <b>Fecha de Registro: </b>
          {fecha_registro}
          <br />
          <b>Estado: </b>
          {estado_delete === "1" ? (
            <strong className="label label-successN"> ACTIVO </strong>
          ) : (
            <strong className="label label-dangerN"> INACTIVO </strong>
          )}
          <br />
          <b>Rol: </b>
          {rol === "administrador" ? (
            <strong className="label label-successN"> Administrador </strong>
          ) : (
            <strong className="label label-dangerN"> Empresa </strong>
          )}
          <br />
          <b>Regimen Tributario: </b>
          {reg_abreviatura} -- N° {num_reg_trib} <br />
          <br />
          {email &&
            (email !== "" ? (
              <>
                <FontAwesomeIcon icon={faEnvelope} size="sm" className="icon-datos" /> {email}
              </>
            ) : (
              <span> </span>
            ))}
          <br />
          {celular &&
            (celular !== "" ? (
              <>
                <a href={`https://wa.me/${celular}`} target="_blank" title="Whatsapp">
                  <button className="redes-btn-fill redes-btn-whatsapp"> <FontAwesomeIcon icon={faWhatsapp} size="lg" /> </button>
                </a>
                {celular}
              </>
            ) : (
              <span> </span>
            ))}
          <br />
          <br />
        </Col>
        <Col xs={12} sm={4}>
          <img src={imagen} alt="imagen-cambiarDefault" title={nombre} width="70%" />
        </Col>
      </Row>

      <Row>
        <br />
        {descripcion &&
          (descripcion !== "" ? (
            <>
              <b>Descripción:</b>
              <p> {descripcion} </p>
            </>
          ) : (
            <span> </span>
          ))}

        <br />
        <b>Productos:</b>
        <PtProductos productos={productos} />
        <br />
      </Row>

      <Row>
        <Col xs={12} sm={8}>
          <br /> <br />
          <b>Persona a Cargo:</b>
          <br />
          {perNombre &&
            (perNombre !== "" ? (
              <>
                <b>Nombre:</b> {perNombre} {perApellido} <br />
              </>
            ) : (
              <span> </span>
            ))}
          {perEmail &&
            (perEmail !== "" ? (
              <>
                <FontAwesomeIcon icon={faEnvelope} size="sm" className="icon-datos"/>
                {perEmail}
              </>
            ) : (
              <span> </span>
            ))}
          <br />
          {perCelular &&
            (perCelular !== "" ? (
              <>
                <a href={`https://wa.me/${perCelular}`} target="_blank" title="Whatsapp" >
                  <button className="redes-btn-fill redes-btn-whatsapp"> <FontAwesomeIcon icon={faWhatsapp} size="lg" /> </button>
                </a>
                {perCelular}
              </>
            ) : (
              <span> </span>
            ))}
        </Col>

        <Col xs={12} sm={4}></Col>
      </Row>
    </>
  );
};
export default TabDatos;
