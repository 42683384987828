import ListaTiendas from "./../../../lista/listaTiendas"; //parte-publica

const TabTiendas = ({ id }) => {
  return (
    <>
      <ListaTiendas peticion={`tienda/gestion_empAll&empresa_id=${id}`} />
    </>
  );
};
export default TabTiendas;
