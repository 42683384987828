import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import React, { useState } from "react";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import PtDatos from "./ptDatos";
import PtDescripcion from "./ptDescripcion";
import PtProductos from "./ptProductos";

import PtTiendas from "./ptTiendas";
import PtPagweb from "./ptPagweb.js";

const ModalEmpresa = ({
  id,
  subcategoria_id,
  nombre,
  num_reg_trib,
  email,
  descripcion,
  productos,
  imagen,
  celular,
  fecha_inicio,
  fecha_final,
  subcateg_nombre,
  cat_nombre,
  reg_abreviatura,
}) => {
  const [key, setKey] = useState("descripcion");

  return (
    <>
      <div className="bordeEmpresa">
        <Row className="tab-row">
          <Col xs={12} sm={3} className="img-modal">
            <img src={imagen} alt={nombre} className="img-fluid" />
          </Col>

          <Col xs={12} sm={9}>
            <Tabs id="controlled-tab-example"  activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="datos" title="Datos">
                <div className="paddingTab">
                  <PtDatos
                    id={id}
                    nombre={nombre}
                    descripcion={descripcion}
                    num_reg_trib={num_reg_trib}
                    email={email}
                    celular={celular}
                    fecha_inicio={fecha_inicio}
                    fecha_final={fecha_final}
                    subcateg_nombre={subcateg_nombre}
                    cat_nombre={cat_nombre}
                    reg_abreviatura={reg_abreviatura}
                  />
                  <hr />
                  <PtPagweb id={id} />
                </div>
              </Tab>

              <Tab eventKey="descripcion" title="Descripción">
                <div className="paddingTab">
                  <PtDescripcion descripcion={descripcion} />
                </div>
              </Tab>

              <Tab eventKey="producto" title="Productos">
                <div className="paddingTab">
                  <PtProductos productos={productos} />
                </div>
              </Tab>

              <Tab eventKey="tienda" title="Tiendas">
                <div className="paddingTab">
                  <PtTiendas id={id} />
                </div>
              </Tab>

            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ModalEmpresa;
