import React from "react";

import { Outlet, Navigate } from "react-router-dom";

import Header from "./Header";
import Footer from "./../Footer";

import Sidebar from "./Sidebar";
import useAuth from "./../../../hooks/useAuth";

const PrivateLayout = () => {
  //Restringir acceso a la parte privada -> si NO esta identificado
  //ANTES <Outlet />
  const { auth, loading } = useAuth();

  if (loading) {
    return <h1>Cargando .... </h1>;
  } else {
    return (
      <>
        {/* LAYOUT*/}
        <Header />

        {/* Contenido principal */}
        <section>
          {auth.id ? <Outlet /> : <Navigate to="/login" />}
          {/* Outlet, todos los componentes que cargue cada ruta */}
        </section>

        {/* Barra Lateral 
				<Sidebar/>
				*/}

        {/*footer*/}
        <Footer />
      </>
    );
  }
};
export default PrivateLayout;
