import ListaTiendas from "./../lista/listaTiendas";

const PtTiendas = ({ id }) => {
  return (
    <>
      <ListaTiendas peticion={`tienda/gestion_emp&empresa_id=${id}`} />
    </>
  );
};
export default PtTiendas;
