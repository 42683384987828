import React from "react";
import { Routes, Route, BrowserRouter, Link } from "react-router-dom";

import { AuthProvider } from "./../context/AuthProvider";

import PublicLayout from "./../components/layout/public/PublicLayout";
import PrivateLayout from "./../components/layout/private/PrivateLayout";

//pagPrivadas
import People from "../components/pagPrivadas/People";
import Perfil from "./../components/pagPrivadas/Perfil";

//---login-user
import Logout from "./../components/user/Logout";


//pagPublicas
import Inicio from "./../components/pagPublicas/Inicio";
import Categorias from "./../components/pagPublicas/Categorias";
import Busqueda from "./../components/pagPublicas/Busqueda";

import Nosotros from "./../components/pagPublicas/Nosotros";
import Servicios from "./../components/pagPublicas/Servicios";
import Contacto from "./../components/pagPublicas/Contacto";

//---login-user
import Login from "./../components/user/Login";


//pagPublicas internas
import Subcategorias from "../components/pagPublicas/categorias/Subcategorias";
import Empresas from "../components/pagPublicas/categorias/Empresas";



//----------------------------- privadas
import RegDepartamento from "./../components/pagPrivadas/Lugar/RegDepartamento";
import RegProvincia from "./../components/pagPrivadas/Lugar/RegProvincia";
import RegDistrito from "./../components/pagPrivadas/Lugar/RegDistrito";

import RegCategoria from "./../components/pagPrivadas/Administrar/RegCategoria";
import RegSubcategoria from "../components/pagPrivadas/Administrar/RegSubcategoria";
import RegEmpresa from "./../components/pagPrivadas/Administrar/RegEmpresa";
import RegBusquedaExistencia from "../components/pagPrivadas/Administrar/RegBusquedaExistencia";
import RegBusquedaBloques from "../components/pagPrivadas/Administrar/RegBusquedaBloques";


import RegInfo from "./../components/pagPrivadas/Empresa/RegInfo";
import RegTiendas from "./../components/pagPrivadas/Empresa/RegTiendas";


const Routing = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        {/* CONTENIDO CENTRAL */}
        <section className="bg-section">
          <Routes>
            {/*Ruta Publica  path='/' */}
            <Route path="/" element={<PublicLayout />}>
              <Route index element={<Inicio />} />              
              {/*<Route path="inicio" element={<Inicio />} />*/}
              <Route path="categorias" element={<Categorias />} />
              <Route path="subcategorias/:categNombre" element={<Subcategorias />} />
              <Route path="empresas/:subcategNombre" element={<Empresas />} />


              <Route path="busqueda" element={<Busqueda />} />

              <Route path="nosotros" element={<Nosotros />} />
              <Route path="servicios" element={<Servicios />} />
              <Route path="contacto" element={<Contacto />} />

              <Route path="login" element={<Login />} />


            </Route>

            {/*Ruta Privada */}
            <Route path="/social" element={<PrivateLayout />}>
              <Route index element={<People />} />
              {/*<Route path="people" element={<People />} />*/}

              <Route path="perfil" element={<Perfil/> } />
              <Route path="logout" element={<Logout />} />


              <Route path="regDepartamento" element={<RegDepartamento />} />
              <Route path="regProvincia" element={<RegProvincia />} />
              <Route path="regDistrito" element={<RegDistrito />} />


              <Route path="regCategoria" element={<RegCategoria />} />
              <Route path="regSubcategoria" element={<RegSubcategoria />} />
              <Route path="regEmpresa" element={<RegEmpresa />} />
              <Route path="regBusquedaExistencia" element={<RegBusquedaExistencia />} />
              <Route path="regBusquedaBloques" element={<RegBusquedaBloques />} />
              

              <Route path="regInfo" element={<RegInfo />} />
              <Route path="regTiendas" element={<RegTiendas />} />
             
              
            </Route>

            <Route path="*" element={
                <>
                  <p>
                    <h1> Error 404</h1>
                    <Link to=""> Volver al inicio </Link>
                  </p>
                </>
              }
            />
          </Routes>
        </section>

      </AuthProvider>
    </BrowserRouter>
  );
};
export default Routing;
