import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import imagenBusqueda from "./../../assets/images/imgPromocion.png";

import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";

import ListaEmpresas from "./../lista/listaEmpresas";

const Busqueda = () => {
  const [departamento, setDepartamento] = useState([]); //setIdArticulos setData es
  const [provincia, setProvincia] = useState([]); //setIdArticulos setData es
  const [distrito, setDistrito] = useState([]); //setIdArticulos setData es

/*
  const peticionEmp = async (opcion) => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}lugar/listaDep`) //lista departamentos
      .then((response) => {
        setDepartamento(response.data); //inicializo y lo guardo en setdepartamento
      });
  };
*/
  const peticionDepGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}lugar/listaDep`) //lista departamentos
      .then((response) => {
        setDepartamento(response.data); //inicializo y lo guardo en setdepartamento
      });
  };

  const peticionProvGet = async (opcion) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}lugar/listaProv&departamento_id=${opcion}`
      )
      .then((response) => {
        setProvincia(response.data);
      });
  };

  const handlerCArgarProvincias = function (e) {
    const opcion = e.target.value;
    //console.log(opcion);
    peticionProvGet(opcion);
  };

  const peticionDisGet = async (opcion) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}lugar/listaDist&provincia_id=${opcion}`
      )
      .then((response) => {
        setDistrito(response.data);
      });
  };

  const handlerCArgarDistritos = function (e) {
    const opcion = e.target.value;
    console.log(opcion);
    peticionDisGet(opcion);
  };

  useEffect(() => {
    peticionDepGet();
  }, []);

  //----------------------------------------------------------
  //----------------------------------------------------------
  //con FORMIK
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  const [valorRpta, setValorRpta] = useState({
    producto: "",
    departamento: 0,
    provincia: 0,
    distrito: 0,
  });

  const txtAlign = {
    textAlign: "left",
  };

  // e.preventDefault(); en handleSubmit de formik

  /*
const valorRpta = {{
nombre: '',
departamento: 0,
provincia: 0,
distrito: 0
}}
console.log("antes " + valorRpta)
*/
  //----------------------------------------------------------

  return (
    <>
      <div className="cabecera-menu"> </div>

      <div className="hheader hheader-busqueda">
        <div className="hheader__thumbnail">
          <img
            src={imagenBusqueda}
            alt="Baraja de Cartas - Categorias"
            className="hheader__image"
          />
        </div>
        <div className="hheader__texts">
          <p className="hheader__title">Busquedas</p>
          <p className="hheader__paragraph">
            A floral, solar and voluptuous interpretation composed by Olivier
            Polge, Perfumer-Creator for the House of CHANEL.
          </p>
        </div>
      </div>

      <div className="item-section">
        <h2> Contenido de la busqueda </h2>

        <Row className="padding-horizontal">
          <Formik
            initialValues={{
              producto: "",
              departamento: 0,
              provincia: 0,
              distrito: 0,
            }}
            validate={(valores) => {
              let errores = {};

              //validacion producto
              if (!valores.producto) {
                errores.producto = "Por favor ingrese el producto que busca";
              } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.producto)) {
                errores.producto =
                  "El producto solo puede contener letras y espacios";
              }

              return errores;
            }}
            onSubmit={(valores, { resetForm }) => {
              setValorRpta({
                producto: valores.producto,
                departamento: valores.departamento,
                provincia: valores.provincia,
                distrito: valores.distrito,
              });

              console.log("lugares:");
              console.log(valores);

              //---resetForm(); //limpia el formulario, pero te pone como al inicio
              cambiarFormularioEnviado(true);
              setTimeout(() => cambiarFormularioEnviado(false), 4000);
            }}
          >
            {({ errors }) => (
              <Form>
                <Row>
                  <Col>
                    <h2>Buscar - Formik</h2>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group" md={2} sm={2} xs={12} style={txtAlign} >
                    <label htmlFor="producto">Producto</label>
                    <Field
                      type="text"
                      id="producto"
                      name="producto"
                      placeholder="agregar producto"
                      className={"form-control"}
                    />
                    <ErrorMessage name="producto" component={() => (
                        <div className="error"> {errors.producto} </div>
                      )}
                    />
                  </Col>
                  <Col className="form-group" md={3} sm={3} xs={12} style={txtAlign} >
                    <label htmlFor="departamento">Departamento</label>
                    <Field
                      name="departamento"
                      as="select"
                      onClick={handlerCArgarProvincias}
                      className={"form-control"}
                    >
                      <option value="0"> --- departamento --- </option>
                      {departamento.map(({ id, nombre }) => (
                        <option key={id} value={id}>{nombre}</option>
                      ))}
                    </Field>
                  </Col>
                  <Col className="form-group" md={3} sm={3} xs={12} style={txtAlign} >
                    <label htmlFor="provincia"> Provincia</label>
                    <Field
                      name="provincia"
                      as="select"
                      onClick={handlerCArgarDistritos}
                      className={"form-control"}
                    >
                      <option value="0"> --- provincia --- </option>
                      {provincia.map(({ id, nombre }) => (
                        <option key={id} value={id}> {nombre} </option>
                      ))}
                    </Field>
                  </Col>

                  <Col
                    className="form-group" md={3} sm={3} xs={12} style={txtAlign} >
                    <label htmlFor="distrito">Distrito</label>
                    <Field
                      name="distrito"
                      as="select"
                      className={"form-control"}
                    >
                      <option value="0"> --- distrito --- </option>
                      {distrito.map(({ id, nombre }) => (
                        <option key={id} value={id}>{nombre}</option>
                      ))}
                    </Field>
                  </Col>

                  <Col className="form-group" md={1} sm={1} xs={12} style={txtAlign}>
                    <label htmlFor="password"> </label> <br />
                    <button type="submit" className="btn btn-primary">Buscar</button>
                    {formularioEnviado && <p className="exito"> Enviado!!! </p>}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Row>

        <hr />

        <Row className="padding-horizontal">
          {valorRpta.producto && (
            <ListaEmpresas
              peticion={`empresa/gestion_busqueda&departamento_id=${valorRpta.departamento}&provincia_id=${valorRpta.provincia}&distrito_id=${valorRpta.distritos}&productos=${valorRpta.producto}`}
            />
          )}
        </Row>
      </div>
    </>
  );
};
export default Busqueda;
