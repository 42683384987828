import React from 'react';

import { createContext, useState, useEffect } from 'react';

import {Global} from './../helpers/Global';
import axios from "axios";



//se crea Context/AuthProvider   + hooks/use

const AuthContext = createContext();


export const AuthProvider = ({children}) =>{

	//const [compartido, setCompartido] = useState("Compartida en todos los componentes"); //estadoPrueba
	const [auth, setAuth] = useState({});
	const [loading, setLoading] = useState(true); //cargando


	//cada vez que se cargue o actualizo la pantalla esto carga
	useEffect(()=>{
		authUser();
	},[]);

	//comprobar el token y sacar la informacion del usuario
	const authUser = async() =>{

		//Sacar datos usuario identificado del localStorage
		const token = localStorage.getItem("token");
		const user = localStorage.getItem("user");


		//Comprobar si tengo el token y el user
		if(!token || !user ){
			setLoading(false); //para que no se queda la pag. Cargando... si no hay token  ni user
			return false;
		}

		//Transformar los datos a un objeto de javascript
		const userObj= JSON.parse(user);
		const userId = userObj.id;
		//console.log("userID: ");
		//console.log(userId);



		//Peticion ajax al backend que compruebe el token y que me devuelva todos los datos del usuario
		/*
		const request = await fetch(Global.url + "user/profile/" + userId, {
			method:"GET",
			headers:{
				"Content-Type":"application/json",
				"Authorization": token
			}
		});

		const data = await request.json();
		console.log(data.user);


		//Setear el estado de auth
		setAuth(data.user);
		*/


		//Peticion ajax al backend que compruebe el token y que me devuelva todos los datos del usuario
	 	await axios
	      .get(Global.url+"usuario/editar&id="+ userId)
	      .then((response) => {
	        setAuth(response.data);
	        console.log("dentro" + auth);
	      });

	      setLoading(false);

	}
	

	return(
		<AuthContext.Provider value= { { auth, setAuth, loading } }>
			{children}
		</AuthContext.Provider>



		)
}


export default AuthContext;
