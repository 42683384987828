import React from "react";

const Contacto = () => {
  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        <h2> Contacto </h2>
      </div>
    </>
  );
};
export default Contacto;
