import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//boostrap
import 'bootstrap/dist/css/bootstrap.min.css';

import "./assets/css/css_eli.css";
import "./assets/css/css_clau.css";

//nuevos
import "./assets/css/css_layout.css";
import "./assets/css/css_nav.css";
import "./assets/css/css_footer.css";

import "./assets/css/css_root.css";
import "./assets/css/css_estructura.css";
import "./assets/css/css_unidad.css";
import "./assets/css/css_section.css";

import "./assets/css/css_cabecera.css";
import "./assets/css/css_administracion.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
