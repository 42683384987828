import {useState, useEffect} from 'react';
import axios from 'axios';

//import Row from 'react-bootstrap/Row'

import Tienda from '../unidad/Tienda'

import MensajeCargando from './../../hooks/mensajeCargando'
import MensajeArrayVacio from './../../hooks/mensajeArrayVacio'

import Accordion from 'react-bootstrap/Accordion'

const ListaTiendas = ({peticion}) =>{

	const [data, setData] = useState();

	const peticionGet=async()=>{
		//await axios.get(`${baseUrl}${peticion}`)
		await axios.get(`${process.env.REACT_APP_URL_API}${peticion}`)
		.then(response=>{
			//console.log(response.data); //muestra en console F12
			setData(response.data);
		})
	}

	//-----------------------------------------------------
	useEffect(()=>{
		peticionGet();
	},[peticion])
	
	//-----------------------------------------------------
	/*
src\componentes\lista\listaTiendas.js
  Line 30:4:  React Hook useEffect has a missing dependency: 'peticionGet'. 
  Either include it or remove the dependency array  react-hooks/exhaustive-deps

	*/

	return(
		<>

	<Accordion>
		{ 			
 
			data ?(
					(data.length ===0) ? 
						( 
							<MensajeArrayVacio mensaje=" No se encontraron Tiendas " />
						) : (
							data.map(({
							id, 
							empresa_id, 
							distrito_id, 
							direccion, 
							telefono, 
							celular, 
							horario, 
							visualizacion, 
							dep_nombre, 
							prov_nombre, 
							dist_nombre
							}) => (								
								<Tienda 
									key = {id}
									id = {id} 
									empresa_id = {empresa_id}
									distrito_id = {distrito_id}
									direccion ={direccion} 
									telefono = {telefono}
									celular = {celular}
									horario = {horario}
									visualizacion = {visualizacion}
									dep_nombre = {dep_nombre}
									prov_nombre = {prov_nombre}
									dist_nombre = {dist_nombre}
								/>
							))						
						)
				):(
					<MensajeCargando mensaje="Cargando Lista de Tiendas" />
			)
		}
		</Accordion>

		</>
	)
}
export default ListaTiendas



