import Row from 'react-bootstrap/Row'

const MensajeCargando = ({mensaje}) =>{

	return(
		<>
		<Row className="padding-horizontal">
			<span className="alert alert-warning mensaje-carg" > {mensaje} </span>
    	</Row>
		</>
	)
}
export default MensajeCargando