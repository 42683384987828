import React from "react";
import { Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTags,
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faYoutube,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import logotipo from "./../../assets/images/logotipo.jpg";

const Footer = () => {
  return (
    <>
      <Row className="main-footerNav">
        <Col xs={12} sm={6} md={3} className="columna">
          <h2> </h2>
          <div class="footer-info">
            <img src={logotipo} alt="logotipo" />
            <span> Logotipo de la empresa ABC y mas palabras </span>
     
          </div>
        </Col>

        <Col xs={12} sm={6} md={3} className="columna">
          <h2>EMPRESA </h2>
          <div class="footer-info">
            <span class="iconoaqui"><FontAwesomeIcon icon={faTags} /> &nbsp; &nbsp;</span>
            <ul>
              <li> <Link to="#">  Nosotros </Link></li>
              <li> <Link to="#"> Servicios </Link></li>
              <li> <Link to="#"> Pagos </Link></li>
            </ul>
          </div>
        </Col>

        <Col xs={12} sm={6} md={3} className="columna">
          <h2> CONTÁCTANOS </h2>
          <div class="lineaVertical">
            <FontAwesomeIcon icon={faPhoneAlt} color="#68b3c8" size="lg" /> &nbsp; &nbsp; +51-54-232921, +51-54-218956 <br />
            <FontAwesomeIcon icon={faEnvelope} color="#68b3c8" size="lg" /> &nbsp; &nbsp; schule@gmail.com <br />
            <FontAwesomeIcon icon={faMapMarkerAlt} color="#68b3c8" size="lg" /> &nbsp; &nbsp; Avenida Fernadini, Sachaca, Arequipa - Perú
          </div>
        </Col>

        <Col xs={12} sm={6} md={3} className="columna">
          <h2>Redes Sociales </h2>
          <div class="lineaVertical">
            <div class="social-links">
              <Link to="#"> <FontAwesomeIcon icon={faFacebookF} /> </Link>
              <Link to="#"> <FontAwesomeIcon icon={faYoutube} /> </Link>
              <Link to="#"> <FontAwesomeIcon icon={faInstagram} /> </Link>
              <Link to="#"> <FontAwesomeIcon icon={faTags} /> </Link>
              <Link to="#"> <FontAwesomeIcon icon={faWhatsapp} /> </Link>
            </div>
          </div>
        </Col>
        <hr />
      </Row>
      <Row className="main-footer">
        <Col xs={12} sm={6}>
          <p>
            <b>Buscar & Encontrar por dataSoftTEAM &copy; 2021 | Todos los
              derechos reservados</b>
          </p>
        </Col>
        <Col xs={12} sm={6}>
          <p className="align-right">
           Diseñado y Desarrollado por 
            <a href="#"  target="_blanck"> dataSoftTEAM </a>
          </p>
        </Col>
      </Row>
    </>
  );
};
export default Footer;
