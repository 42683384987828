import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

const SubCategoria = ({ id, nombre, visualizacion, imagen }) => {
  return (
    <>
      <Col xs={12} sm={6} md={3}>
        <Link to={`/empresas/${nombre}`} className="card-subcategoria__enlace"  key={id} >
          <div className="card-subcategoria">
            <div className="card-subcategoria__thumbnail">
              <img src={imagen} alt={nombre} className="card-subcategoria__img" />
            </div>
            <h2 className="card-subcategoria__titulo">{nombre}</h2>
          </div>
        </Link>
      </Col>
    </>
  );
};
export default SubCategoria;
