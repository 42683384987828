
import { Col } from "react-bootstrap";

//import { Link } from "react-router-dom"
import {useState} from 'react';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
//import {Modal, ModalBody, ModalFooter, ModalHeader} from 'react-bootstrap';

//import axios from 'axios';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'

import ModalEmpresa from './modalEmpresa'


const Empresa = ({
	id,
	subcategoria_id,
	nombre,
	num_reg_trib,
	email,
	descripcion,
	productos,
	imagen,
	celular,
	fecha_inicio,
	fecha_final,
	subcateg_nombre,
	cat_nombre,
	reg_abreviatura
}) =>{


	const [modalInsertar, setModalInsertar] = useState(false); 

	const abrirCerrarModalInsertar = () =>{
		setModalInsertar(!modalInsertar);
	}


	return(
		<>
		<Col xs={12} sm={6} md={3}> 
		<div className="card-empresa" key={id} >
            <div className="card-empresa__thumbnail">
              <img
                src={imagen}
                alt={nombre}
                className="card-empresa__image"
              />
              <div className="card-empresa__boton">              
				<button className ="card-empresa__btn-modal" onClick={()=> abrirCerrarModalInsertar() }> 
                         <b> Ver Detalle &nbsp;  <FontAwesomeIcon icon={faArrowRight} /> &nbsp;  </b> 
                    </button>
              </div>
            </div>

            <div className="row card-empresa__descripcion">
              <p className="card-empresa__nombre"> {nombre} </p>
            </div>
          </div>
		</Col>

{/* aqui puedo add el FREE (ModalEmpresaFree) o PRO (ModalEmpresa)  */}

		<Modal isOpen={modalInsertar} size="lg" >
		  <ModalHeader> Detalle de la Empresa </ModalHeader>
		  <ModalBody>
			<div>
			  <ModalEmpresa 
				id = {id}
				subcategoria_id = {subcategoria_id}
				nombre = {nombre}
				num_reg_trib = {num_reg_trib}
				email = {email}
				descripcion = {descripcion}
				productos = {productos}
				imagen = {imagen}
				celular = {celular}
				fecha_inicio = {fecha_inicio}
				fecha_final = {fecha_final}
				subcateg_nombre = {subcateg_nombre}
				cat_nombre = {cat_nombre}
				reg_abreviatura = {reg_abreviatura}
				/>
			</div>
		  </ModalBody>
		  <ModalFooter>
			<button className="btn btn-primary" onClick={()=> abrirCerrarModalInsertar() }> Listo </button>
		  </ModalFooter>
		</Modal>


		</>
	)
}
export default Empresa

