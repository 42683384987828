import { useState, useEffect } from "react";
import axios from "axios";

import SubCategoria from "../unidad/SubCategoria";

import MensajeCargando from "./../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../hooks/mensajeArrayVacio";

import imgSubcategoria from './../../assets/images/imgSubcategoria.png';

const ListaSubcategorias = ({ peticion }) => {
  const [data, setData] = useState();

  const peticionGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}${peticion}`)
      .then((response) => {
        //console.log(response.data); //muestra en console F12
        setData(response.data);
      });
  };

  useEffect(() => {
    peticionGet();
  }, [peticion]);

  return (
    <>
      {data ? (
        data.length === 0 ? (
          <MensajeArrayVacio mensaje=" No se encontraron Subcategorias " />
        ) : (
          data.map(({ id, nombre, visualizacion, imagen }) => (
            <SubCategoria
              key={id}
              id={id}
              nombre={nombre}
              visualizacion={visualizacion}
              imagen={	
                (imagen !== "")  ? (            	
                  `${process.env.REACT_APP_URL_API}uploads/${imagen}` 
	            ) : ( `${imgSubcategoria}` )}
            />
          ))
        )
      ) : (
        <MensajeCargando mensaje="Cargando Lista de Subcategorias" />
      )}
    </>
  );
};
export default ListaSubcategorias;
