import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

const Categoria = ({ id, nombre, visualizacion, imagen }) => {
  return (
    <>
      <Col xs={12} sm={6} md={3} >
        <Link to={`/subcategorias/${nombre}`} className="card-categoria__enlace"  key={id} >
          <div className="card-categoria">
            <div className="card-categoria__thumbnail">
              <img src={imagen} alt={nombre} className="card-categoria__img" />
            </div>
            <h2 className="card-categoria__titulo"> {nombre} </h2>
          </div>
        </Link>
      </Col>
    </>
  );
};
export default Categoria;
