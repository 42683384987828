import React from "react";
import { Row } from "react-bootstrap";

import imagenCategoria from "./../../assets/images/imagenCategoria.png";

import ListaCategorias from "./../lista/listaCategorias";

const Categorias = () => {
  return (
    <>
      <div className="cabecera-menu"> </div>

      <div className="hheader hheader-categ">
        <div className="hheader__thumbnail">
          <img
            src={imagenCategoria}
            alt="Baraja de Cartas - Categorias"
            className="hheader__image"
          />
        </div>
        <div className="hheader__texts">
          <p className="hheader__title">CATEGORIAS</p>
          <p className="hheader__paragraph">
            A floral, solar and voluptuous interpretation composed by Olivier
            Polge, Perfumer-Creator for the House of CHANEL.
          </p>
        </div>
      </div>

      <div className="item-section">
        <Row>
          <ListaCategorias peticion="categoria/gestionEmpresas" />
         </Row>
      </div>
    </>
  );
};
export default Categorias;
