import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";

import FErrorMessage from "./../../../hooks/FErrorMessage";
import MensajeArrayVacio from "./../../../hooks/mensajeArrayVacio";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import ListarBusqueda from "./lista/ListarBusqueda";


const RegBusquedaBloques = () => {
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  const initialValues = {
    categoria_id: "0",
    subcategoria_id: "0",
    departamento_id: "0",
    provincia_id: "0",
    distrito_id: "0",
  };

  const validationSchema = Yup.object({
    //busqExistencia: Yup.string().required("Por favor ingrese su nombre"),
    // busqExistencia: Yup.string().required("Por favor seleccione uno"),
    // descripcion: Yup.string()
    //  .min(5, "Demasiado pequeño, too small!")
    //  .max(500, "Too Long String")
    //  .required("Por favor ingrese la descripción"),
    //---

    //categoria_id: Yup.string().required("Por favor ingrese su categoria 123"),
    categoria_id: Yup.string().required("Required"),
  });

  const [categoria, setCategoria] = useState([]);
  const [subCategoria, setSubCategoria] = useState([]);

  const [departamento, setDepartamento] = useState([]); //setIdArticulos setData es
  const [provincia, setProvincia] = useState([]); //setIdArticulos setData es
  const [distrito, setDistrito] = useState([]); //setIdArticulos setData es


  //Categoria
  const peticionCategGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}categoria/gestion`) //lista departamentos
      .then((response) => {
        setCategoria(response.data); //inicializo y lo guardo en setdepartamento
      });
  };

  const peticionsubcategGet = async (opcion) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}subcategoria/gestion&categoria_id=${opcion}`
      )
      .then((response) => {
        setSubCategoria(response.data);
      });
  };

  //Lugar
  const peticionDepGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}lugar/listaDepTienda`) //lista departamentos
      .then((response) => {
        setDepartamento(response.data); //inicializo y lo guardo en setdepartamento
      });
  };

  const peticionProvGet = async (opcion) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}lugar/listaProvTienda&departamento_id=${opcion}`
      )
      .then((response) => {
        setProvincia(response.data);
      });
  };

  const peticionDisGet = async (opcion) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}lugar/listaDistTienda&provincia_id=${opcion}`
      )
      .then((response) => {
        setDistrito(response.data);
      });
  };

  const handlerCargarSubcateg = function (e) {
    const opcion = e.target.value;
    //console.log(opcion);
    peticionsubcategGet(opcion);
  };

  const handlerCargarProvincias = function (e) {
    const opcion = e.target.value;
    //console.log(opcion);
    peticionProvGet(opcion);
  };

  const handlerCargarDistritos = function (e) {
    const opcion = e.target.value;
    console.log(opcion);
    peticionDisGet(opcion);
  };

  const [valorRpta, setValorRpta] = useState({
    categoria_id: "0",
    subcategoria_id: "0",
    departamento_id: "0",
    provincia_id: "0",
    distrito_id: "0",
  });

  useEffect(() => {
    peticionCategGet();
    peticionDepGet();
  }, []);

  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        <Row>
          <Card className="formulario">
            <Card.Header className="formulario__titulo">
              Busqueda de Existencia de Empresas
            </Card.Header>
            <Card.Body className="formulario__body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(valores, { resetForm }) => {
                  setValorRpta({
                    categoria_id: valores.categoria_id,
                    subcategoria_id: valores.subcategoria_id,
                    departamento_id: valores.departamento_id,
                    provincia_id: valores.provincia_id,
                    distrito_id: valores.distrito_id,
                  });

                  resetForm(); //limpia el formulario, pero te pone como al inicio
                  //nos ayuda a limpiar nuestros combobox despues de enviar el formulario, para que esten vacios y no con los valores anteriores
                  subCategoria.length > 1 && setSubCategoria([]);
                  provincia.length > 1 && setProvincia([]);
                  distrito.length > 1 && setDistrito([]);

                  //console.log("Formulario enviado");
                  cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                  setTimeout(() => cambiarFormularioEnviado(false), 2000);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="categoria_id">Categoría</label>
                        <Field
                          name="categoria_id"
                          as="select"
                          onClick={handlerCargarSubcateg}
                          className={"form-control"}
                        >
                          <option value="0"> --- select categoria --- </option>
                          {categoria.map(({ id, nombre }) => (
                            <option key={id} value={id}> {nombre} </option>
                          ))}
                        </Field>
                        <FErrorMessage name="categoria_id" />
                      </Col>

                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="subcategoria_id">SubCategoría</label>
                        <Field
                          name="subcategoria_id"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> --- select subCategoria --- </option>
                          {subCategoria.map(({ id, nombre }) => (
                            <option key={id} value={id}> {nombre} </option>
                          ))}
                        </Field>
                        <FErrorMessage name="subcategoria_id" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={4} md={4}>
                        <label htmlFor="departamento_id">Departamento</label>
                        <Field
                          name="departamento_id"
                          as="select"
                          onClick={handlerCargarProvincias}
                          className={"form-control"}
                        >
                          <option value="0"> --- select departamento --- </option>
                          {departamento.map(({ id, nombre }) => (
                            <option key={id} value={id}> {nombre} </option>
                          ))}
                        </Field>
                        <FErrorMessage name="departamento_id" />
                      </Col>

                      <Col className="form-group" xs={12} sm={4} md={4}>
                        <label htmlFor="provincia_id">Provincia</label>
                        <Field
                          name="provincia_id"
                          as="select"
                          onClick={handlerCargarDistritos}
                          className={"form-control"}
                        >
                          <option value="0"> --- select provincia --- </option>
                          {provincia.map(({ id, nombre }) => (
                            <option key={id} value={id}> {nombre} </option>
                          ))}
                        </Field>
                        <FErrorMessage name="provincia_id" />
                      </Col>

                      <Col className="form-group" xs={12} sm={4} md={4}>
                        <label htmlFor="distrito_id">Distrito</label>
                        <Field
                          name="distrito_id"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> --- select distrito --- </option>
                          {distrito.map(({ id, nombre }) => (
                            <option key={id} value={id}> {nombre} </option>
                          ))}
                        </Field>
                        <FErrorMessage name="distrito_id" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={12} md={12}>
                        <label htmlFor="password"> </label> <br />
                        <button type="submit" className="btn btn-primary"> Buscar x Bloque </button>
                        {formularioEnviado && (
                          <p className="formulario__msj-enviado"> Enviado x Bloque!!! </p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Row>
        <br />
        <h1>Resultados </h1>
        
        <Row className="padding-horizontal"> 
          {valorRpta.categoria_id !=='0' ? (
            <ListarBusqueda
              peticion={`empresa/gestion_busquedaInfoBloque&categoria_id=${valorRpta.categoria_id}&subcategoria_id=${valorRpta.subcategoria_id}&departamento_id=${valorRpta.departamento_id}&provincia_id=${valorRpta.provincia_id}&distrito_id=${valorRpta.distrito_id}`}
          />        
          ) : ( 
          <MensajeArrayVacio mensaje=" Debe de seleccionar una Categoria " /> 
          )        
        }
      </Row>

      <br /> <br />
      </div>
    </>
  );
};

export default RegBusquedaBloques;
