import { useState, useEffect } from "react";
import axios from "axios";


import Categoria from "./../unidad/Categoria";

import MensajeCargando from "./../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../hooks/mensajeArrayVacio";

import imgCategoria from './../../assets/images/imgCategoria.png';

const ListaCategorias = ({ peticion }) => {

  const [data, setData] = useState();

  const peticionGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}${peticion}`)
      .then((response) => {
        //console.log(response.data); //muestra en console F12
        setData(response.data);
      });
  };
// eslint-disable-next-line react-hooks/exhaustive-deps
  //-----------------------------------------------------
  useEffect(() => {
    peticionGet();
    
  }, [peticion]); //   }, [peticion]);

  //-----------------------------------------------------

  return (
    <>
      {/*<Categoria id= "5" nombre="nombre1" visualizacion="SI" imagen="nota" /> */}

      {/*	{data.map(cat=>(		

					<Categoria id= {cat.id} nombre={cat.nombre} visualizacion={cat.visualizacion} imagen={cat.imagen} />
				))
			}
			*/}



{/*

{
			`${process.env.REACT_APP_URL_API}uploads/${imagen}`


				((imagen !== "") || (imagen !== null) ) ? (            	
	            	`${process.env.REACT_APP_URL_API}uploads/${imagen}`
	            ) : ( {imagen6} )
            
        } 

*/}

      {data ? (
        data.length === 0 ? (
          <MensajeArrayVacio mensaje=" No se encontraron Categorias " />
        ) : (
          data.map(({ id, nombre, visualizacion, imagen }) => (
            <Categoria
              key={id}
              id={id}
              nombre={nombre}
              visualizacion={visualizacion}
              imagen={	
                (imagen !== "")  ? (            	
                  `${process.env.REACT_APP_URL_API}uploads/${imagen}` 
	            ) : ( `${imgCategoria}` )}
            />
          ))
        )
      ) : (
        <MensajeCargando mensaje="Cargando Lista de Categorias" />
      )}
    </>
  );
};
export default ListaCategorias;
