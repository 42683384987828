import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import { Modal, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

import MensajeCargando from "./../../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../../hooks/mensajeArrayVacio";
import FErrorMessage from "./../../../hooks/FErrorMessage";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const RegProvincia = () => {

  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [departamento, setDepartamento] = useState([]); //setIdArticulos setData es
  const [provincia, setProvincia] = useState([]); //setIdArticulos setData es

  const validationSchema = Yup.object({
    nombre: Yup.string().required("Por favor agregue un Nombre"),
  });

  const peticionDepGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}lugar/listaDepTienda`) //lista departamentos
      .then((response) => {
        setDepartamento(response.data); //inicializo y lo guardo en setdepartamento
      });
  };

  const peticionProvGet = async (opcion) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}lugar/listaProvTienda&departamento_id=${opcion}`
      )
      .then((response) => {
        setProvincia(response.data);
      });
  };

  const handlerCargarProvincias = function (e) {
    const opcion = e.target.value;
    //console.log(opcion);
    peticionProvGet(opcion);
  };

  //-----------------------------------------------------
  const [valorSeleccionado, setValorSeleccionado] = useState({});

  //una funcion que permita asignar al estado cual es la fila que le selecciono, tambien se va reutilizar para eliminar => tenemos un segundo parametro
  const seleccionar = (valor, caso) => {
    setValorSeleccionado(valor);
    //console.log(valorSeleccionado);
    //(caso ==="Editar")?	abrirCerrarModalEditar(): abrirCerrarModalEliminar()
    if (caso === "Eliminar") {
      abrirCerrarModalEliminar();
    }
  };

  const initialValues = {
    departamento_id: "0",
    nombre: "",
  };

  const initialValuesEdit = {
    departamento_id: valorSeleccionado.departamento_id,
    nombre: valorSeleccionado.nombre,
  };

  //-----------------------------------------------------
  const peticionPost = async (valores) => {
    var f = new FormData();
    f.append("departamento_id", valores.departamento_id);
    f.append("nombre", valores.nombre);

    await axios
      .post(`${process.env.REACT_APP_URL_API}lugar/saveProv`, f)
      .then((response) => {
        setProvincia(provincia.concat(response.data)); //si es correcta que se concatene al estado data. Antes: setData(response.data) AHORA:
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  const peticionPut = async (valores) => {
    var f = new FormData();
    f.append("departamento_id", valores.departamento_id);
    f.append("nombre", valores.nombre);

    await axios
      .post(
        `${process.env.REACT_APP_URL_API}lugar/updateProv&id=${valorSeleccionado.id}`,
        f
      ) //Request URL: http://localhost/react_api_proy01/proyecto-php-poo-mvc-sql-apicategoria/categorias/update&id=69
      .then((response) => {
        var dataNueva = provincia;
        dataNueva.map((valor) => {
          if (valor.id === valorSeleccionado.id) {
            valor.nombre = valores.nombre;
          }
        });
        setProvincia(dataNueva);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  //delete
  const [modalEliminar, setModalEliminar] = useState(false);
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionDelete = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_URL_API}lugar/eliminarProv&id=${valorSeleccionado.id}`
      )
      .then((response) => {
        setProvincia(
          provincia.filter((valor) => valor.id !== valorSeleccionado.id)
        ); //filtroData, se quedan todos los elementos que sean diferentes al id seleccionado .. luego del filtrado se agrega al estado y luego cerrar el modal ... Tb se debe de add el manejo de errores

        setValorSeleccionado({});
        abrirCerrarModalEliminar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    peticionDepGet();
  }, []);

  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        <Row>
          <Col xs={12} sm={6} md={4}>
            <Card className="formulario">
              <Card.Header className="formulario__titulo">Provincia</Card.Header>
              <Card.Body className="formulario__body">
                <Formik
                  enableReinitialize
                  //initialValues={initialValues}
                  initialValues={valorSeleccionado.id >= 1? initialValuesEdit : initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(valores, { resetForm }) => {
                    if (valorSeleccionado.id >= 1) {
                      peticionPut(valores);
                      setValorSeleccionado({});
                    } else {
                      peticionPost(valores);
                    }

                    //console.log(valores);
                    resetForm(); //limpia el formulario, pero te pone como al inicio
                    //console.log("Formulario enviado");
                    cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                    setTimeout(() => cambiarFormularioEnviado(false), 2000);
                  }}
                >
                  {({ errors }) => (
                    <Form>
                      <Col className="form-group" xs={12} sm={12} md={12}>
                        <label htmlFor="departamento_id">Departamentos </label>
                        <Field
                          name="departamento_id"
                          as="select"
                          onClick={handlerCargarProvincias}
                          className={"form-control"}
                        >
                          <option value="0">--- select departamento ---</option>
                          {departamento.map(({ id, nombre }) => (
                            <option key={id} value={id}>{nombre}</option>
                          ))}
                        </Field>
                        <FErrorMessage name="departamento_id" />
                      </Col>

                      <Col className="form-group" xs={12} sm={12} md={12}>
                        <label htmlFor="nombre">Provincia</label>
                        <Field
                          type="text"
                          name="nombre"
                          placeholder="Agregar Provincia"
                          className={"form-control"}
                        />
                        <FErrorMessage name="nombre" />
                      </Col>

                      <Row>
                        <Col className="form-group" xs={12} sm={12} md={12}>
                          <label htmlFor=""> </label> <br />
                          {valorSeleccionado.id >= 1 ? (
                            <button type="submit" className="btn btn-primary">UpdateProv</button>
                          ) : (
                            <button type="submit" className="btn btn-primary">GuardaProv</button>
                          )}
                          {formularioEnviado && (
                            <p className="formulario__msj-enviado">Enviado Prov!!!</p>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
          
          <Col xs={12} sm={6} md={8}>
            <h2> Tabla de Provincias </h2>
            <div className="tab-scroll-vertical">
              {provincia ? (
                provincia.length === 0 ? (
                  <MensajeArrayVacio mensaje=" No se encontraron las Provincias " />
                ) : (
                  <table
                    id="tabRegDepartamento3"
                    className="table table-striped"
                  >
                    <thead>
                      <tr>
                        <th> ID </th>
                        <th> Provincia </th>
                        <th> Acciones </th>
                      </tr>
                    </thead>
                    <tbody>
                      {provincia.map((valor) => (
                        <tr key={valor.id}>
                          <td>{valor.id}</td>
                          <td>{valor.nombre}</td>
                          <td>
                            <button
                              className="btn btn-simple btn-warningNs"
                              onClick={() => seleccionar(valor, "Editar")}
                            > <FontAwesomeIcon icon={faEdit} />
                            </button> &nbsp;
                            <button
                              className="btn btn-simple btn-dangerNs"
                              onClick={() => seleccionar(valor, "Eliminar")}
                            ><FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              ) : (
                <MensajeCargando mensaje="Cargando Lista de Provincias" />
              )}
            </div>
          </Col>
        </Row>

        <Modal isOpen={modalEliminar}>
          <ModalBody>
            ¿Estás seguro que deseas eliminar la Provincia - 
            {valorSeleccionado && valorSeleccionado.nombre }?
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => peticionDelete()}>Sí</button>
            <button className="btn btn-secondary" onClick={() => abrirCerrarModalEliminar()}>No</button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default RegProvincia;
