import React from "react";

const Nosotros = () => {
  return (
    <>
      <div className="parallax">
        <div className="square-container">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
        </div>
        <div className="parallax-content">
          <div className="content-wrapper">
            <h2>Nosotros</h2>
            <div className="scrollable-content">
              <p>
                Nuestra empresa ha creado un centro comercial virtual innovador, que funciona como una página amarilla en línea. Este centro comercial virtual ofrece a los compradores una experiencia única de compra en línea, con la posibilidad de encontrar y comprar productos de una amplia gama de tiendas y comercios.
              </p>
              <p>
                Lo que nos diferencia de otros centros comerciales en línea es nuestra capacidad de proporcionar a los comerciantes un análisis detallado de sus compradores y su negocio. Nuestro sistema de dashboards y análisis permite a los comerciantes realizar un seguimiento del comportamiento de sus clientes, analizar las tendencias de compra y optimizar su estrategia de venta.
              </p>
              <p>
                Además, nuestro centro comercial virtual está organizado de manera clara y fácil de usar, con una navegación intuitiva que permite a los usuarios encontrar fácilmente lo que están buscando. Con nuestro sistema, los compradores pueden disfrutar de una experiencia de compra personalizada y sin problemas, mientras que los comerciantes pueden obtener información valiosa para mejorar su negocio.
              </p>
              <p>
                En resumen, nuestra empresa ofrece una solución innovadora y efectiva para los comerciantes que buscan mejorar su estrategia de venta en línea, al mismo tiempo que ofrece a los compradores una experiencia de compra en línea única y personalizada. ¡Únete a nuestro centro comercial virtual y descubre una nueva forma de comprar y vender en línea!
              </p>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .parallax {
          position: relative;
          height: calc(100vh - 64px); /* Subtract the height of the nav bar */
          overflow: hidden;
          background-color: black;
        }

        .square-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          perspective: 1000px;
          animation: squareContainerAnimation 20s infinite linear; /* Animation for square container movement */
        }

        .square {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100px;
          height: 100px;
          transform: translate(-50%, -50%);
          background-color: rgba(255, 255, 255, 0.1);
          animation: squareAnimation 10s infinite alternate; /* Animation for square movement */
        }

        .square:nth-child(1) {
          top: 20%;
          left: 30%;
          animation-delay: -2s; /* Delay for animation start */
        }

        .square:nth-child(2) {
          top: 40%;
          left: 70%;
          animation-delay: -4s; /* Delay for animation start */
        }

        .square:nth-child(3) {
          top: 60%;
          left: 10%;
          animation-delay: -6s; /* Delay for animation start */
        }

        .square:nth-child(4) {
          top: 80%;
          left: 50%;
          animation-delay: -8s; /* Delay for animation start */
        }

        .square:nth-child(5) {
          top: 100%;
          left: 90%;
          animation-delay: -10s; /* Delay for animation start */
        }

        .parallax-content {
          position: relative;
          z-index: 1;
          text-align: center;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }

        .content-wrapper {
          width: 100%;
          max-width: 800px;
          padding: 20px;
          animation: contentWrapperAnimation 20s infinite linear; /* Animation for content wrapper movement */
        }

        .scrollable-content {
          max-height: calc(100vh - 200px);
          overflow-y: auto;
          scrollbar-width: thin;
          scrollbar-color: transparent transparent; /* Transparent scroll bar */
          transition: scrollbar-color 0.3s ease-in-out;
        }

        .scrollable-content::-webkit-scrollbar {
          width: 8px;
        }

        .scrollable-content::-webkit-scrollbar-track {
          background-color: transparent;
        }

        .scrollable-content::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 4px;
        }

        @keyframes squareContainerAnimation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes squareAnimation {
          0% {
            transform: translate(-50%, -50%);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }

        @keyframes contentWrapperAnimation {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(20px);
          }
          100% {
            transform: translateY(0);
          }
        }
      `}</style>
    </>
  );
};

export default Nosotros;
