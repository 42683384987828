import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faTags } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import useAxiosGet from "./../../../../hooks/useAxiosGet";

const TabEstadisticas = ({ id }) => {
  const paginas = useAxiosGet(`empresa/paginas&empresa_id=${id}`);
  const puntMensual = useAxiosGet(`empresa/puntMensual&empresa_id=${id}`);

  return (
    <>
      <h2> Estadisticas - Pág. de Redes Sociales</h2>
      <br />

      {paginas && puntMensual && (
        <table id="regCategoria" className="table table-striped">
          <thead>
            <tr>
              <th> ICON </th>
              <th> Pág. Redes Sociales </th>
              <th> Puntaje </th>
            </tr>
          </thead>
          <tbody>
            {paginas.pag_whatsapp !== "" &&
              puntMensual.punt_pag_whatsapp !== 0 && (
                <tr>
                  <td>
                    <a href={paginas.pag_whatsapp} target="_blank" title="whatsapp">
                      <button className="redes-btn-fill redes-btn-whatsapp"> <FontAwesomeIcon icon={faWhatsapp} size="lg" /> </button>
                    </a>
                  </td>
                  <td>{paginas.pag_whatsapp}</td>
                  <td>{puntMensual.punt_pag_whatsapp}</td>
                </tr>
              )}

            {paginas.pag_web !== "" && puntMensual.pun_pag_web !== 0 && (
              <tr>
                <td>
                  <a href={paginas.pag_web} target="_blank" title="web">
                    <button className="redes-btn-fill redes-btn-successN"> <FontAwesomeIcon icon={faGlobe} /> </button>
                  </a>
                </td>
                <td>{paginas.pag_web}</td>
                <td>{puntMensual.pun_pag_web}</td>
              </tr>
            )}

            {paginas.pag_facebook !== "" &&
              puntMensual.pun_pag_facebook !== 0 && (
                <tr>
                  <td>
                     <a href={paginas.pag_facebook} target="_blank" title="facebook">
                      <button className="redes-btn-fill redes-btn-infoN"> <FontAwesomeIcon icon={faFacebookF} /> </button>
                    </a>
                  </td>
                  <td>{paginas.pag_facebook}</td>
                  <td>{puntMensual.pun_pag_facebook}</td>
                </tr>
              )}

            {paginas.pag_youtube !== "" &&
              puntMensual.pun_pag_youtube !== 0 && (
                <tr>
                  <td>
                    <a href={paginas.pag_youtube} target="_blank" title="youtube">
                      <button className="redes-btn-fill redes-btn-dangerN"> <FontAwesomeIcon icon={faYoutube} /> </button>
                    </a>
                  </td>
                  <td>{paginas.pag_youtube}</td>
                  <td>{puntMensual.pun_pag_youtube}</td>
                </tr>
              )}

            {paginas.pag_instagram !== "" &&
              puntMensual.pun_pag_instagram !== 0 && (
                <tr>
                  <td>
                    <a href={paginas.pag_instagram} target="_blank" title="instagram">
                      <button className="redes-btn-fill redes-btn-warningN "> <FontAwesomeIcon icon={faInstagram} /> </button>
                    </a>
                  </td>
                  <td>{paginas.pag_instagram}</td>
                  <td>{puntMensual.pun_pag_instagram}</td>
                </tr>
              )}

            {paginas.pag_otros !== "" && puntMensual.pun_pag_otros !== 0 && (
              <tr>
                <td>
                  <a href={paginas.pag_otros} target="_blank" title="otros">
                    <button className="redes-btn-fill redes-btn-petroleoN"> <FontAwesomeIcon icon={faTags} /> </button>
                  </a>
                </td>
                <td>{paginas.pag_otros}</td>
                <td>{puntMensual.pun_pag_otros}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <h2> Mostrar Gráfico </h2>
      
    </>
  );
};
export default TabEstadisticas;
