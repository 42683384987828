import React, { useState, useEffect } from "react";

const Inicio = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          "https://picsum.photos/v2/list?page=2&limit=30"
        );
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images]);

  const renderImageSet = (imageSet) => {
    return (
      <div className="image-row">
        {imageSet.map((image, index) => (
          <div
            key={index}
            className={`image-frame ${currentIndex % imageSet.length === index ? "active" : ""}`}
          >
            <img src={image.download_url} alt={image.author} />
          </div>
        ))}
      </div>
    );
  };

  const leftImages = images.slice(0, 3);
  const rightImages = images.slice(3, 6);
  const leftImageSets = [leftImages, leftImages, leftImages];
  const rightImageSets = [rightImages, rightImages, rightImages];

  return (
    <>
      <div className="cabecera-menu" />
      <div className="parallax">
        <div className="square-container">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
        </div>
        <div className="wrapper">
          <div className="column-left">
            <div className="image-section">
              {leftImageSets.map((imageSet, index) => (
                <React.Fragment key={index}>
                  {renderImageSet(imageSet)}
                </React.Fragment>
              ))}
            </div>
            <p>Left column text</p>
          </div>
          <div className="main-content">
            <div className="item-section">
              <h2>Inicio</h2>
            </div>
          </div>
          <div className="column-right">
            <p>Right column text</p>
            <div className="image-section">
              {rightImageSets.map((imageSet, index) => (
                <React.Fragment key={index}>
                  {renderImageSet(imageSet)}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Inicio;
