import { useState, useEffect } from "react";
import axios from "axios";

import Empresa from "./../unidad/Empresa";

import MensajeCargando from "./../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../hooks/mensajeArrayVacio";

import imgEmpresa from "./../../assets/images/imgEmpresa.png";

const ListaEmpresas = ({ peticion }) => {
  const [data, setData] = useState();

  const peticionGet = async () => {
    //console.log(`ES::::${process.env.REACT_APP_URL_API}${peticion}`);

    await axios
      .get(`${process.env.REACT_APP_URL_API}${peticion}`)

      .then((response) => {
        console.log(response.data); //muestra en console F12
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //-----------------------------------------------------
  useEffect(() => {
    peticionGet();
  }, [peticion]);

  //-----------------------------------------------------

  return (
    <>
      {data ? (
        data.length === 0 ? (
          <MensajeArrayVacio mensaje=" No se encontraron Empresas " />
        ) : (
          data.map(
            ({
              id,
              subcategoria_id,
              nombre,
              num_reg_trib,
              email,
              descripcion,
              productos,
              imagen,
              celular,
              fecha_inicio,
              fecha_final,
              rol,
              subcateg_nombre,
              subcateg_imagen,
              cat_nombre,
              cat_imagen,
              reg_abreviatura
            }) => (
              <Empresa
                key={id}
                id={id}
                subcategoria_id={subcategoria_id}
                nombre={nombre}
                num_reg_trib={num_reg_trib}
                email={email}
                descripcion={descripcion}
                productos={productos}
                imagen={                  
                  imagen ? (
                          (imagen !== "" )  ? (
                                  `${process.env.REACT_APP_URL_API}uploads/${imagen}`
                                ) : ( `${imgEmpresa}` )
                          ) : ( `${imgEmpresa}` )               
                  }
                celular={celular}
                fecha_inicio={fecha_inicio}
                fecha_final={fecha_final}
                subcateg_nombre={subcateg_nombre}
                cat_nombre={cat_nombre}
                reg_abreviatura={reg_abreviatura}
              />
            )
          )
        )
      ) : (
        <MensajeCargando mensaje="Cargando Lista de Empresas" />
      )}
    </>
  );
};
export default ListaEmpresas;
