import {useContext} from 'react';
import AuthContext from './../context/AuthProvider';

//conseguir la informacion para cada componente

const useAuth = () =>{
	return useContext(AuthContext);		
}
export default useAuth;

