import React from 'react'

const RegInfo = () => {
  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">

        <h2>  Reginfo </h2>
      
      </div>
    </>
  )
}

export default RegInfo
