import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import axios from "axios";
import { Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

//add dataTable ---- Bootstrap and jQuery libraries
//import 'bootstrap/dist/css/bootstrap.min.css';
//---import 'jquery/dist/jquery.min.js';

//import "datatables.net-dt/js/dataTables.dataTables";
//import "datatables.net-dt/css/jquery.dataTables.min.css";
//import $ from "jquery";

import imgEmpresa from "./../../../assets/images/imgEmpresa.png";

import MensajeCargando from "./../../../hooks/mensajeCargando";
import MensajeArrayVacio from "./../../../hooks/mensajeArrayVacio";
import FErrorMessage from "./../../../hooks/FErrorMessage";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const RegEmpresa = () => {
  const [data, setData] = useState([]);

  const [categoria, setCategoria] = useState([]);
  const [subCategoria, setSubCategoria] = useState([]);
  const [reg_trib, setReg_trib] = useState([]);

  const [modalInsertar, setModalInsertar] = useState(false);
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  //mostrar lista de todas las categorias
  const peticionGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}empresa/gestionAllAdmin`)
      .then((response) => {
        //console.log(response.data); //muestra en console F12
        setData(response.data);
        //---setData([]);
      });
  };

  //reg_trib
  const peticionRegTribGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}regimenTributario/gestion`) 
      .then((response) => {
        setReg_trib(response.data); //inicializo y lo guardo en setdepartamento
      });
  };

  //Categoria
  const peticionCategGet = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL_API}categoria/gestion`) 
      .then((response) => {
        setCategoria(response.data); //inicializo y lo guardo en setdepartamento
      });
  };

  const peticionsubcategGet = async (opcion) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL_API}subcategoria/gestion&categoria_id=${opcion}`
      )
      .then((response) => {
        setSubCategoria(response.data);
      });
  };

  const handlerCargarSubcateg = function (e) {
    const opcion = e.target.value;
    //console.log(opcion);
    peticionsubcategGet(opcion);
  };

  //con FORMIK
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  const initialValues = {
    reg_trib_id: "",
    categoria_id: "",
    subcategoria_id: "",
    nombre: "",
    num_reg_trib: "",
    email: "",
    password: "",
    rol: "",
    celular: "",
    perNombre: "",
    perApellido: "",
    perEmail: "",
    perCelular: "",
  };

  const validationSchema = Yup.object({
    //busqExistencia: Yup.string().required("Por favor seleccione uno"),
    //departamento_id:'0',
    //nombre: Yup.string().required("Por favor agregue un Nombre"),
    //visualizacion: Yup.string().required("Seleccione una opcion"),
    nombre: Yup.string().required("Por favor agregue un Nombre"),
    reg_trib_id: Yup.string().required("Seleccione una opcion"),
    categoria_id: Yup.string().required("Seleccione una opcion"),
    subcategoria_id: Yup.string().required("Seleccione una opcion"),

    num_reg_trib: Yup.number().required("Agregue su RUC, son numeros"), //int
    email: Yup.string().required("Agregue su Email"), //add email
    password: Yup.string().required("Agregue su Password"),
    rol: Yup.string().required("Seleccione una opcion"),

    celular: Yup.number().required("Agregue su celular, son numeros"),
    perNombre: Yup.string().required("Por favor agregue Nombre del Encargado"),
    perApellido: Yup.string().required(
      "Por favor agregue Apellido del Encargado"
    ),
    perEmail: Yup.string().required("Agregue su Email"),
    perCelular: Yup.number().required("Agregue su celular, son numeros"),
  });

  //-----------------------------------------------------

  const peticionPost = async (valores) => {
    var f = new FormData();
    f.append("nombre", valores.nombre);
    f.append("reg_trib_id", valores.reg_trib_id);
    f.append("num_reg_trib", valores.num_reg_trib);
    f.append("subcategoria_id", valores.subcategoria_id);
    f.append("email", valores.email);
    f.append("password", valores.password);
    f.append("rol", valores.rol);
    f.append("celular", valores.celular);
    f.append("perNombre", valores.perNombre);
    f.append("perApellido", valores.perApellido);
    f.append("perEmail", valores.perEmail);
    f.append("perCelular", valores.perCelular);

    await axios
      .post(`${process.env.REACT_APP_URL_API}empresa/saveAdmin`, f)
      .then((response) => {
        //---setData(data.concat(response.data)); //se puede concatenar, pero no se muestra los nombres de categ y subcategoria
        peticionGet(); //actualiza toda la tabla
        abrirCerrarModalInsertar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  //editar
  const [modalEditar, setModalEditar] = useState(false);
  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const [valorSeleccionado, setValorSeleccionado] = useState({});

  //una funcion que permita asignar al estado cual es la fila que le selecciono, tambien se va reutilizar para eliminar => tenemos un segundo parametro
  const seleccionar = (valor, caso) => {
    setValorSeleccionado(valor);
    console.log(valorSeleccionado);
    caso === "Editar" ? abrirCerrarModalEditar() : abrirCerrarModalEliminar();
  };

  const initialValuesEdit = {
    reg_trib_id: valorSeleccionado.reg_trib_id,
    categoria_id: valorSeleccionado.cat_id,
    subcategoria_id: valorSeleccionado.subcategoria_id,
    nombre: valorSeleccionado.nombre,
    num_reg_trib: valorSeleccionado.num_reg_trib,
    email: valorSeleccionado.email,
    password: valorSeleccionado.password,
    rol: valorSeleccionado.rol,
    celular: valorSeleccionado.celular,
    perNombre: valorSeleccionado.perNombre,
    perApellido: valorSeleccionado.perApellido,
    perEmail: valorSeleccionado.perEmail,
    perCelular: valorSeleccionado.perCelular,
  };

  const peticionPut = async (valores) => {
    var f = new FormData();
    f.append("nombre", valores.nombre);
    f.append("reg_trib_id", valores.reg_trib_id);
    f.append("num_reg_trib", valores.num_reg_trib);
    f.append("subcategoria_id", valores.subcategoria_id);
    f.append("email", valores.email);
    f.append("password", valores.password);
    f.append("rol", valores.rol);
    f.append("celular", valores.celular);
    f.append("perNombre", valores.perNombre);
    f.append("perApellido", valores.perApellido);
    f.append("perEmail", valores.perEmail);
    f.append("perCelular", valores.perCelular);

    await axios
      .post(
        `${process.env.REACT_APP_URL_API}empresa/updateAdmin&id=${valorSeleccionado.id}`,
        f
      ) 
      .then((response) => {
        /*var dataNueva = data;
        dataNueva.map((valor) => {
          if (valor.id === valorSeleccionado.id) {
            valor.nombre = valores.nombre;
            valor.reg_trib_id = valores.reg_trib_id;
            valor.num_reg_trib = valores.num_reg_trib;
            valor.subcategoria_id = valores.subcategoria_id;
            valor.email = valores.email;
            valor.password = valores.password;
            valor.rol = valores.rol;
          }
        });
        setData(dataNueva);
        */
        peticionGet(); //actualiza toda la tabla
        abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //-----------------------------------------------------
  //delete //no lo elimina, solo lo pone inactivo
  const [modalEliminar, setModalEliminar] = useState(false);
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionDelete = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_URL_API}empresa/eliminarAdmin&id=${valorSeleccionado.id}`
      )
      .then((response) => {
        //setData(data.filter((valor) => valor.id !== valorSeleccionado.id)); //filtroData, se quedan todos los elementos que sean diferentes al id seleccionado .. luego del filtrado se agrega al estado y luego cerrar el modal ... Tb se debe de add el manejo de errores
        peticionGet(); //actualiza toda la tabla
        abrirCerrarModalEliminar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //-----------------------------------------------------
  useEffect(() => {
    peticionGet();
    peticionRegTribGet();
    peticionCategGet();
  }, []);

  //-----------------------------------------------------
  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        <Row>
          <Col xs={12} sm={6} md={6}>
            <h2 className="formulario__titulo"> Registrar Empresa </h2>
          </Col>

          <Col xs={12} sm={6} md={6}>
            <button className="btn btn-success" onClick={() => abrirCerrarModalInsertar()}> Insertar Empresa </button>
          </Col>
        </Row>
        <br />

        <Row>
          <h2> Tabla de Empresas </h2>
          <div className="tab-scroll-vertical">
            {data ? (
              data.length === 0 ? (
                <MensajeArrayVacio mensaje=" No se encontraron Empresas " />
              ) : (
                <table id="tabListarEmpresas" className="table table-striped">
                  <thead>
                    <tr>
                      <th> ID </th>
                      <th> Img Nombre Emp </th>
                      <th> Activo </th>
                      <th> Categoría </th>
                      <th> Subcategoría </th>
                      <th> Acciones </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((valor) => (
                      <tr key={valor.id}>
                        <td>{valor.id}</td>
                        <td>
                          <img
                            src={
                              valor.imagen
                                ? valor.imagen !== ""
                                  ? `${process.env.REACT_APP_URL_API}uploads/${valor.imagen}`
                                  : `${imgEmpresa}`
                                : `${imgEmpresa}`
                            }
                            alt={valor.nombre}
                            title={valor.nombre}
                            width="75px"
                          />
                          &nbsp; {valor.nombre}
                        </td>
                        <td>
                          {valor.estado_delete === "1" ? (
                            <span className="babel label label-successN"> Activo </span>
                          ) : (
                            <span className="babel label label-dangerN"> Inactivo </span>
                          )} 
                        </td>
                        <td>{valor.cat_nombre}</td>
                        <td>{valor.subcateg_nombre}</td>
                        <td>
                          <button className="btn btn-simple btn-warningNs" onClick={() => seleccionar(valor, "Editar")}> <FontAwesomeIcon icon={faEdit} /> </button>
                          &nbsp;
                          <button className="btn btn-simple btn-dangerNs" onClick={() => seleccionar(valor, "Eliminar")}> <FontAwesomeIcon icon={faTrash} /> </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            ) : (
              <MensajeCargando mensaje="Cargando Lista de Categorias" />
            )}
          </div>
        </Row>


        {/* MODAL */}
        <Modal isOpen={modalInsertar}>
          <ModalHeader>
            <h2>Ingresar Empresa </h2>
          </ModalHeader>
          <ModalBody>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(valores, { resetForm }) => {
                  peticionPost(valores);

                  //console.log(valores);

                  resetForm(); //limpia el formulario, pero te pone como al inicio
                  //console.log("Formulario enviado");
                  cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                  setTimeout(() => cambiarFormularioEnviado(false), 2000);
                }}
              >
                {({ errors }) => (
                  <Form>
                    <Row>
                      <Col className="form-group" xs={12} sm={12} md={12}>
                        <label htmlFor="nombre">Empresa</label>
                        <Field
                          type="text"
                          name="nombre"
                          placeholder="Nombre de la Empresa"
                          className={"form-control"}
                        />
                        <FErrorMessage name="nombre" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="reg_trib_id">Tipo Reg. Trib. </label>
                        <Field
                          name="reg_trib_id"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> --- select regTrib --- </option>
                          {reg_trib.map(({ id, abreviatura }) => (
                            <option key={id} value={id}>
                              {abreviatura}
                            </option>
                          ))}
                        </Field>
                        <FErrorMessage name="reg_trib_id" />
                      </Col>

                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="num_reg_trib">Num Reg Tributario</label>
                        <Field
                          type="text"
                          name="num_reg_trib"
                          placeholder="numRegTrib"
                          className={"form-control"}
                        />
                        <FErrorMessage name="num_reg_trib" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="categoria_id">Categoria </label>
                        <Field
                          name="categoria_id"
                          as="select"
                          onClick={handlerCargarSubcateg}
                          className={"form-control"}
                        >
                          <option value="0"> --- select categoria --- </option>
                          {categoria.map(({ id, nombre }) => (
                            <option key={id} value={id}>
                              {nombre}
                            </option>
                          ))}
                        </Field>
                        <FErrorMessage name="categoria_id" />
                      </Col>

                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="subcategoria_id">SubCategoria </label>
                        <Field
                          name="subcategoria_id"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0">--- select subCategoria ---</option>
                          {subCategoria.map(({ id, nombre }) => (
                            <option key={id} value={id}>
                              {nombre}
                            </option>
                          ))}
                        </Field>
                        <FErrorMessage name="subcategoria_id" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="email">Email</label>
                        <Field
                          type="text"
                          name="email"
                          placeholder="Agregar email"
                          className={"form-control"}
                        />
                        <FErrorMessage name="email" />
                      </Col>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="num_reg_trib">Celular</label>
                        <Field
                          type="text"
                          name="celular"
                          placeholder="celular"
                          className={"form-control"}
                        />
                        <FErrorMessage name="celular" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={6} sm={6} md={6}>
                        <label htmlFor="password">Password</label>
                        <Field
                          type="text"
                          name="password"
                          placeholder="Agregar password"
                          className={"form-control"}
                        />
                        <FErrorMessage name="password" />
                      </Col>

                      <Col className="form-group" xs={6} sm={6} md={6}>
                        <label htmlFor="rol">Rol </label>
                        <Field
                          name="rol"
                          as="select"
                          className={"form-control"}
                        >
                          <option value=""> --- seleccionar --- </option>
                          <option value="empresa"> Empresa </option>
                          <option value="administrador"> Administrador </option>
                        </Field>
                        <FErrorMessage name="rol" />
                      </Col>
                    </Row>

                    <br></br>
                    <h1> Personal </h1>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="email">Nombre</label>
                        <Field
                          type="text"
                          name="perNombre"
                          placeholder="Agregar nombre"
                          className={"form-control"}
                        />
                        <FErrorMessage name="perNombre" />
                      </Col>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="num_reg_trib"> Apellido </label>
                        <Field
                          type="text"
                          name="perApellido"
                          placeholder="Agregar apellido"
                          className={"form-control"}
                        />
                        <FErrorMessage name="perApellido" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="email">Email</label>
                        <Field
                          type="text"
                          name="perEmail"
                          placeholder="Agregar email"
                          className={"form-control"}
                        />
                        <FErrorMessage name="perEmail" />
                      </Col>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="num_reg_trib">Celular</label>
                        <Field
                          type="text"
                          name="perCelular"
                          placeholder="Agregar Celular"
                          className={"form-control"}
                        />
                        <FErrorMessage name="perCelular" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={4} md={2}>
                        <label htmlFor=""> </label> <br />
                        <button type="submit" className="btn btn-primary"> GuardaEmp </button>
                        {formularioEnviado && (
                          <p className="formulario__msj-enviado">Enviado Emp!!!</p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => abrirCerrarModalInsertar()}> Cancelar </button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={modalEditar}>
          <ModalHeader>
            <h2> Actualizar Empresa </h2>
          </ModalHeader>
          <ModalBody>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValuesEdit}
                validationSchema={validationSchema}
                onSubmit={(valores, { resetForm }) => {
                  peticionPut(valores);

                 // console.log(valores);

                  resetForm(); //limpia el formulario, pero te pone como al inicio
                  //console.log("Formulario enviado");
                  cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                  setTimeout(() => cambiarFormularioEnviado(false), 2000);
                }}
              >
                {({ errors }) => (
                  <Form>
                    {/*initialValuesEdit.nombre + " " + initialValuesEdit.subcategoria_id */}
                    <Row>
                      <Col className="form-group" xs={12} sm={12} md={12}>
                        <label htmlFor="nombre">Empresa</label>
                        <Field
                          type="text"
                          name="nombre"
                          placeholder="Nombre de la Empresa"
                          className={"form-control"}
                        />
                        <FErrorMessage name="nombre" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="reg_trib_id">Tipo Reg. Trib. </label>
                        <Field
                          name="reg_trib_id"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0"> --- select regTrib --- </option>
                          {reg_trib.map(({ id, abreviatura }) => (
                            <option key={id} value={id}>
                              {abreviatura}
                            </option>
                          ))}
                        </Field>
                        <FErrorMessage name="reg_trib_id" />
                      </Col>

                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="num_reg_trib">Num Reg Tributario</label>
                        <Field
                          type="text"
                          name="num_reg_trib"
                          placeholder="numRegTrib"
                          className={"form-control"}
                        />
                        <FErrorMessage name="num_reg_trib" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="categoria_id">Categoria </label>
                        <Field
                          name="categoria_id"
                          as="select"
                          onClick={handlerCargarSubcateg}
                          //onChange={handlerCargarSubcateg} buscar otro que se actualice cuando haga un click interno
                          className={"form-control"}
                        >
                          <option value="0"> --- select categoria --- </option>
                          {categoria.map(({ id, nombre }) => (
                            <option key={id} value={id}>
                              {nombre}
                            </option>
                          ))}
                        </Field>
                        <FErrorMessage name="categoria_id" />
                      </Col>

                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="subcategoria_id">SubCategoria </label>
                        <Field
                          name="subcategoria_id"
                          as="select"
                          className={"form-control"}
                        >
                          <option value="0">--- select subCategoria ---</option>
                          {subCategoria.map(({ id, nombre }) => (
                            <option key={id} value={id}>
                              {nombre}
                            </option>
                          ))}
                        </Field>
                        <FErrorMessage name="subcategoria_id" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="email">Email</label>
                        <Field
                          type="text"
                          name="email"
                          placeholder="Agregar email"
                          className={"form-control"}
                        />
                        <FErrorMessage name="email" />
                      </Col>

                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="num_reg_trib">Celular</label>
                        <Field
                          type="text"
                          name="celular"
                          placeholder="celular"
                          className={"form-control"}
                        />
                        <FErrorMessage name="celular" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={6} sm={6} md={6}>
                        <label htmlFor="password">Password</label>
                        <Field
                          type="text"
                          name="password"
                          placeholder="Agregar password"
                          className={"form-control"}
                        />
                        <FErrorMessage name="password" />
                      </Col>

                      <Col className="form-group" xs={6} sm={6} md={6}>
                        <label htmlFor="rol">Rol </label>
                        <Field
                          name="rol"
                          as="select"
                          className={"form-control"}
                        >
                          <option value=""> --- seleccionar --- </option>
                          <option value="empresa"> Empresa </option>
                          <option value="administrador"> Administrador </option>
                        </Field>
                        <FErrorMessage name="rol" />
                      </Col>
                    </Row>

                    <br></br>
                    <h1> Personal </h1>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="email">Nombre</label>
                        <Field
                          type="text"
                          name="perNombre"
                          placeholder="Agregar nombre"
                          className={"form-control"}
                        />
                        <FErrorMessage name="perNombre" />
                      </Col>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="num_reg_trib"> Apellido </label>
                        <Field
                          type="text"
                          name="perApellido"
                          placeholder="Agregar apellido"
                          className={"form-control"}
                        />
                        <FErrorMessage name="perApellido" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="email">Email</label>
                        <Field
                          type="text"
                          name="perEmail"
                          placeholder="Agregar email"
                          className={"form-control"}
                        />
                        <FErrorMessage name="perEmail" />
                      </Col>
                      <Col className="form-group" xs={12} sm={6} md={6}>
                        <label htmlFor="num_reg_trib">Celular</label>
                        <Field
                          type="text"
                          name="perCelular"
                          placeholder="Agregar Celular"
                          className={"form-control"}
                        />
                        <FErrorMessage name="perCelular" />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="form-group" md={12} sm={12} xs={12}>
                        <label htmlFor=""> </label> <br />
                        <button type="submit" className="btn btn-primary">
                          Actualizar
                        </button>
                        &nbsp;
                        {formularioEnviado && (
                          <p className="msj-enviarForm"> Actualizar Emp!!! </p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => abrirCerrarModalEditar()}> Cancelar </button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={modalEliminar}>
          <ModalBody>
            ¿Estás seguro que deseas eliminar la Empresa -{" "}
            {valorSeleccionado && valorSeleccionado.nombre}?
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={() => peticionDelete()}> Sí </button>
            <button className="btn btn-secondary" onClick={() => abrirCerrarModalEliminar()}> No </button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default RegEmpresa;
