import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faTags,
  faArrowRight,
  faEnvelope,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

//import {useState} from 'react';
import axios from 'axios';

const PtDatos = ({
  id,
  nombre,
  descripcion,
  num_reg_trib,
  email,
  celular,
  fecha_inicio,
  fecha_final,  
  subcateg_nombre,
  cat_nombre,
  reg_abreviatura
}) => {



  //const [data, setData] = useState();
	const peticionPutPagWhatsapp=async()=>{
		await axios.get(`${process.env.REACT_APP_URL_API}puntajeMensual/puntPagWhatsapp&empresa_id=${id}`)  
		.then(response=>{
			//setData(response.data);
		}).catch(error =>{
			console.log(error);
			})
	}

  return (
    <>
      <br />
      <Row>
        <Col xs={12} sm={8}>
          <h2> {nombre} </h2>
        </Col>
        <Col xs={12} sm={4}>
          <h3 class="label label-ruc label-right">
            {reg_abreviatura} -- N° {num_reg_trib}{" "}
          </h3>
        </Col>
      </Row>

      {
			descripcion &&  (
				 (descripcion !== "")  ? (            	
	            	<>
                <b>Descripción:</b>
                <p> {descripcion} </p>
                </>
	            ) : (<span> </span>)
            )
        }

      
      <span class="label label-categsubcateg">
        <FontAwesomeIcon icon={faTag} /> {cat_nombre} &nbsp;
        <FontAwesomeIcon icon={faArrowRight} /> &nbsp;
        <FontAwesomeIcon icon={faTags} /> {subcateg_nombre}
      </span>
      <br />

      {
			email &&  (
				 (email !== "")  ? ( 
          <>           	
            <FontAwesomeIcon icon={faEnvelope} className="icon-datos" /> {email}
          </>
	          ) : (<span> </span>)
          )
        }        
      <br />
     
      {
			celular &&  (
				 (celular !== "")  ? ( 
          <>           	
          <a href={`https://wa.me/${celular}`} target="_blank" title="Whatsapp">
            <button className="redes-btn-fill redes-btn-whatsapp" onClick={()=>peticionPutPagWhatsapp()}>
              <FontAwesomeIcon icon={faWhatsapp} size="lg" />
            </button>
          </a>  {celular}
          </>
	          ) : (<span> </span>)
          )
        }

     
    </>
  );
};
export default PtDatos;
