import Row from 'react-bootstrap/Row'

const MensajeArrayVacio = ({mensaje}) =>{

	return(
		<>
		<Row className="padding-horizontal">
			<span className="alert alert-danger mensaje-array-vacio" > {mensaje} </span>
    	</Row>
		</>
	)
}
export default MensajeArrayVacio