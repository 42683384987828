import React from "react";

import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTags,
  faComment,
  faGlobe,
  faPhone,
  faPhoneAlt,
  faEnvelope,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";

//import imgCervantes3 from "./../../assets/imagen/claudia-cervantes-jilaja-2.jpg";

import avatar from "../../../assets/images/user.png";

import useAuth from "../../../hooks/useAuth";
import { Global } from "../../../helpers/Global";

/*
  const url = `https://example.com/${path}/${id}`;
*/
const Menu = () => {
  const { auth } = useAuth(); //tengo disponible el objeto del usuario

  const [scrolled, setScrolled] = useState(false);

  //mostrar el scroll cuando la medida del desplace sea mayor a 50
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <Navbar className={scrolled ? "scrolled" : ""} expand="lg" variant="light" >
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="logo">
            Proy <img alt="House" src={avatar} width="30" height="30" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/social">  Inicio </Nav.Link>


              {/*<Nav.Link as={NavLink} to="/social/people"> people </Nav.Link>*/}
            
              
              <NavDropdown title="RegLugar" id="basic-nav-dropdown" >
                <NavDropdown.Item as={NavLink} to="/social/regDepartamento"> Departamento </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/social/regProvincia"> Provincia </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/social/regDistrito"> Distrito </NavDropdown.Item>
              </NavDropdown>
             
              <NavDropdown title="RegAdmin" id="basic-nav-dropdown" >
                <NavDropdown.Item as={NavLink} to="/social/regCategoria"> Categoria </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/social/regSubcategoria"> SubCategoria </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/social/regEmpresa"> Empresa </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={NavLink} to="/social/regBusquedaExistencia"> Busqueda x Existencia </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/social/regBusquedaBloques"> Busqueda x Bloques </NavDropdown.Item>

                 

              </NavDropdown>
              
              <NavDropdown title="RegEmpresa" id="basic-nav-dropdown" >
                <NavDropdown.Item as={NavLink} to="/social/regInfo"> Info </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/social/regTiendas"> Tiendas </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={NavLink} to="/social"> people </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Nav>

              <Nav.Link as={NavLink} to="/#"> Otro </Nav.Link>

              <NavDropdown title={<> {auth.rol} {auth.imagen !== "default.png" && ( <img src={Global.url + "uploads/" + auth.imagen} className="list-end__img" alt="Foto de perfil" width="30" height="30" /> )}
                      {auth.imagen === "default.png" && ( <img src={avatar} className="list-end__img" alt="Foto de perfil" width="30" height="30" /> )} </>} id="basic-nav-dropdown" >
                <li>
                  <div>
                    <div>
                      {auth.imagen !== "default.png" && ( <img src={Global.url + "uploads/" + auth.imagen} className="list-end__img" alt="Foto de perfil" width="30" height="30" /> )}
                      {auth.imagen === "default.png" && ( <img src={avatar} className="list-end__img" alt="Foto de perfil" width="30" height="30" /> )}
                    </div>
                    <div> 
                      {auth.nombre} {auth.apelllido}
                      <p> {auth.email} </p>
                    </div>
                  </div>
                </li>
                <NavDropdown.Item as={NavLink} to="/social"> people </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/social/perfil"> <FontAwesomeIcon icon={faEnvelope} /> Tu perfil </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={NavLink} to="/social/logout"> fa-arrow-right-from-bracket - Cerrar Sesión </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Menu;
