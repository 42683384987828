import React, { useState } from "react";
import { Row, Tabs, Tab } from "react-bootstrap";

import TabDatos from "./tabDatos";
import TabPagweb from "./tabPagweb";
import TabTiendas from "./tabTiendas";

const ModalInfoDatos = ({
  id,
  reg_abreviatura,
  num_reg_trib,
  cat_nombre,
  subcateg_nombre,
  nombre,
  email,
  descripcion,
  productos,
  imagen,
  fecha_registro,
  celular,
  estado_delete,
  fecha_inicio,
  fecha_final,
  rol,
  perNombre,
  perApellido,
  perEmail,
  perCelular,
}) => {
  const [key, setKey] = useState("datos");

  return (
    <>
      <div className="bordeEmpresa">
        <Row className="tab-row">
          <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
            
            <Tab eventKey="datos" title="Datos">
              <div className="paddingTab">
                <TabDatos
                  id={id}
                  reg_abreviatura={reg_abreviatura}
                  num_reg_trib={num_reg_trib}
                  cat_nombre={cat_nombre}
                  subcateg_nombre={subcateg_nombre}
                  nombre={nombre}
                  email={email}
                  descripcion={descripcion}
                  productos={productos}
                  imagen={imagen}
                  fecha_registro={fecha_registro}
                  celular={celular}
                  estado_delete={estado_delete}
                  rol={rol}
                  perNombre={perNombre}
                  perApellido={perApellido}
                  perEmail={perEmail}
                  perCelular={perCelular}
                />
              </div>
            </Tab>

            <Tab eventKey="paginaweb" title="Pag. Web">
              <div className="paddingTab">
                <TabPagweb id={id} />
              </div>
            </Tab>

            <Tab eventKey="tienda" title="Tiendas">
              <div className="paddingTab">
                <TabTiendas id={id} />
              </div>
            </Tab>
          </Tabs>
        </Row>
      </div>
    </>
  );
};
export default ModalInfoDatos;
