import React from "react";
import { ErrorMessage } from "formik";

// --> {touched.email && error.email ? <span>{error.email}</span> : null}
const FErrorMessage = ({ name }) => {
  return (
    <div className="msj-error">
      <ErrorMessage name={name} />
    </div>
  );
};
export default FErrorMessage;
