import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";

import FErrorMessage from "./../../../hooks/FErrorMessage";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import ListarBusqueda from "./lista/ListarBusqueda";

const RegBusquedaExistencia = () => {
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  const [valorRpta, setValorRpta] = useState({
    busqExistencia: "",
    descripcion: "",
  });

  const initialValues = {
    busqExistencia: "",
    descripcion: "",
  };

  const validationSchema = Yup.object({
    //busqExistencia: Yup.string().required("Por favor ingrese su nombre"),
    busqExistencia: Yup.string().required("Por favor seleccione uno"),
    descripcion: Yup.string()
      .min(5, "Demasiado pequeño, too small!")
      .max(500, "Too Long String")
      .required("Por favor ingrese la descripción"),
  });


  return (
    <>
      <div className="cabecera-menu"> </div>
      <div className="item-section">
        
        <Row>
          <Card className="formulario">
            <Card.Header className="formulario__titulo">
              Busqueda de Existencia de Empresas
            </Card.Header>
            <Card.Body className="formulario__body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(valores, { resetForm }) => {
                  setValorRpta({
                    busqExistencia: valores.busqExistencia,
                    descripcion: valores.descripcion,
                  });

                  //esta es la funcion a ejecutar, puede ser add a una api
                  resetForm(); //limpia el formulario, pero te pone como al inicio
                  //console.log(valores);
                  //console.log("Formulario enviado");
                  cambiarFormularioEnviado(true); //pero quiero que despues de 5 segundos se cambie a falso
                  setTimeout(() => cambiarFormularioEnviado(false), 5000);
                }}
              >
                {({ errors }) => (
                  <Form>
                    <Row>
                      <Col className="form-group" xs={12} sm={4} md={4}>
                        <label htmlFor="busqExistencia">Busqueda Por</label>
                        <Field
                          name="busqExistencia"
                          as="select"
                          className={"form-control"}
                        >
                          <option value=""> --- seleccionar --- </option>
                          <option value="nombreEmp"> Nombre Empresa </option>
                          <option value="numRegTrib"> Num Reg Trib. </option>
                        </Field>
                        <FErrorMessage name="busqExistencia" />
                      </Col>

                      <Col className="form-group" xs={12} sm={4} md={6}>
                        <label htmlFor="descripcion">Descripción</label>
                        <Field
                          type="text"
                          id="descripcion"
                          name="descripcion"
                          placeholder="Agregar Descripción"
                          className={"form-control"}
                        />
                        <FErrorMessage name="descripcion" />
                      </Col>

                      <Col className="form-group" xs={12} sm={4} md={2}>
                        <label htmlFor="password"> </label> <br />
                        <button type="submit" className="btn btn-primary"> Buscar </button>
                        {formularioEnviado && (
                          <p className="formulario__msj-enviado"> Enviado bxe!!!</p>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Row>

        <br />
        <h1>Resultados </h1>
        <Row className="padding-horizontal">
          {valorRpta.busqExistencia && (
            <ListarBusqueda
              peticion={`empresa/gestion_busquedaExistencia&busqExistencia=${valorRpta.busqExistencia}&descripcion=${valorRpta.descripcion}`}
            />
          )}
        </Row>
      </div>
    </>
  );
};

export default RegBusquedaExistencia;
